.Home-pricing {
    background-color: #261d39;
    padding-top: 108px;
    padding-bottom: 145px;
    position: relative;
    z-index: 1;

    @media screen and (max-width: 1280px) {
        padding-bottom: 100px;
    }
    @media screen and (max-width: 699px) {
        padding-top: 84px;
    }

    .Home-sectionContentCaption {
        color: #eee;
    }

    .Home-sectionContentHeadline {
        color: #fff;
    }

    .Home-sectionContentHeadline {
        font-weight: 900;
        font-size: 34px;
        line-height: 40px;
        max-width: 100%;
        @media screen and (max-width: 699px) {
            font-size: 24px;
            line-height: 32px;
        }
    }

    .Home-sectionColumn {
        width: 100%;
    }

    .Home-sectionContainer {
        flex-direction: column;
        padding: 0;
        @media screen and (max-width: 1264px) {
            width: 100%;
            max-width: 100%;
        }
    }
}

.Home-pricingBackground {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
    z-index: -1;
}
.Home-priceRange {
    width: 100%;
}

.Home-pricesHolder {
    max-width: 100%;
}

.Home-prices {
    max-width: calc(100% - 68px);
    display: flex;
    margin: 64px 34px 0;
    @media screen and (max-width: 699px) {
        margin-top: 32px;
    }

    .Home-priceColumn {
        @media screen and (max-width: 699px) {
            width: 100%;
            margin: 0;
        }

        .Home-submissionCtaContainer {
            margin: 0 auto;
        }

        .Home-buttonCta {
            margin: 0 0 24px;
        }
    }
}
