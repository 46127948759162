@use '../../../shared/theme';
@use '../../../shared/utils';
@use '../../../shared/breakpoints';

.page__header {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 100;
    height: 72px;
    padding: 0 24px;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.14), 0 3px 1px rgba(0, 0, 0, 0.12), 0 1px 5px rgba(0, 0, 0, 0.2);

    &:before {
        content: '';
        background: linear-gradient(106.54deg, #140078 -4.67%, #6c31bc 112.32%);
        transition: opacity 250ms ease-in-out;
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: 1;
    }

    &--transparent:not(&--scrolled) {
        &:before {
            opacity: 0;
        }
    }

    &--no-shadow {
        box-shadow: none;
    }

    &--scrolled {
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.14), 0 3px 1px rgba(0, 0, 0, 0.12), 0 1px 5px rgba(0, 0, 0, 0.2);
    }

    @include breakpoints.down(xs) {
        padding: 0 16px 0 16px;
        height: 60px;
        align-items: center;
    }
    &__brand {
        display: block;
        padding: 9px 24px 9px 0;
        @include breakpoints.down(md) {
            padding: 12px 0px 12px 40px;
            display: flex;
        }
        &-holder {
            @include breakpoints.down(xs) {
                width: 100%;
            }
        }
        &-image {
            width: auto;
            height: 48px;

            @include breakpoints.down(xs) {
                height: 36px;
            }
        }
    }
    &__nav {
        $paddingSize: 18px;
        &,
        &-holder {
            display: flex;
            @include breakpoints.down(sm) {
                display: none;
            }
        }
        &-holder {
            align-items: stretch;
        }
        &-item {
            position: relative;
            overflow: hidden;
            padding: 0 $paddingSize;
            font-weight: 500;
            letter-spacing: 0.35px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            &:after {
                content: '';
                display: block;
                width: calc(100% - #{$paddingSize * 2});
                height: 4px;
                border-radius: 2px 2px 0 0;
                position: absolute;
                transform: translate3d(0, 4px, 0) scale(0.5, 1);
                bottom: 0;
                left: $paddingSize;
                background-color: theme.$primaryColor;
                opacity: 0.5;
                transition: utils.transition(('transform', 'opacity', 'background-color'));
            }
            &--active:after,
            &:hover:after {
                transform: translate3d(0, 0, 0) scale(1, 1);
            }
            &--active {
                color: theme.$primaryColor;
                &:after {
                    opacity: 1;
                }
            }
            &:hover:after {
                background-color: #fff;
                opacity: 0.8;
            }
        }
    }
    &__auth {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        min-width: 130px;
    }
    &__drawer {
        display: none;
        color: #fff;
        width: 1px;
        margin-left: -1px;
        @include breakpoints.down(xs) {
            display: inline-flex;
            align-items: center;
        }
    }
}
