@use '../../../shared/breakpoints';

.page__content,
.page__body {
    display: flex;
    flex-direction: column;
}

.page__body {
    min-height: 100vh;
}

.page__content {
    flex: 1 1 auto;
    @include breakpoints.down(768) {
        background: #ffffff;
    }
}
