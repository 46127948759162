.Home-hero-uk {
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: #fff;

    @media screen and (max-width: 767px) {
        min-height: 70vh;
    }
}

.Home-hero-ukContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: end;

    &--alignStart {
        align-items: start;
    }
}

@media screen and (min-width: 1600px) {
    .Home-hero-ukContainer {
        max-width: 1330px;
    }
}
@media screen and (min-width: 1200px) and (max-width: 1599px) {
    .Home-hero-ukContainer {
        max-width: 100%;
    }
}
.Home-hero-ukTitle,
.Home-hero-ukSubtitle {
    color: #000;
}

.Home-hero-ukContainer {
    .Home-heroCta {
    }

    .Home-sectionColumn {
        @media screen and (max-width: 767px) {
            padding: 0;
        }
    }
}

.Home-hero-ukTitle {
    font-weight: 300;
    font-size: 60px;
    line-height: 60px;
    letter-spacing: 0;
    text-align: center;

    max-width: 840px;
    margin-bottom: 62px;
    @media screen and (max-width: 767px) {
        font-size: 26px;
        line-height: 26px;
        margin-bottom: 79px;
    }

    b {
        font-weight: 500;
    }
}

.Home-hero-ukSubtitle {
    font-weight: 400;
    font-size: 36px;
    line-height: 36px;
    letter-spacing: 0;
    text-align: center;

    max-width: 900px;
    @media screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 16px;
        padding: 0 40px;
    }
}

.Home-hero-ukCtaRow {
    margin-top: 141px;
    display: flex;
    justify-content: center;
    width: 100%;
    @media screen and (max-width: 767px) {
        flex-direction: column;
        margin-top: 68px;
    }
}

.Home-ukCtaButtonContainer {
    width: 30%;
    @media screen and (max-width: 767px) {
        width: 100%;
        padding-left: 56px;
        padding-right: 56px;
    }
}

.Home-ukButtonCta {
    width: 100%;
    height: 72px;
    border-radius: 40px;
    background-color: #30016e;

    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0;
    color: #fff;
    text-transform: capitalize;

    &:hover {
        background-color: #30016e;
        color: #fff;
    }
    @media screen and (max-width: 767px) {
        width: 100%;
        height: 47px;
        font-size: 16px;
        line-height: 16px;
    }
}

.Home-hero-whyAgsCta {
    width: 100%;
    height: 72px;
    border-radius: 40px;
    border-width: 1px;
    border-color: #30016e;

    font-family: 'Ara', Helvetica, Arial, sans-serif;
    font-weight: 800;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0;

    margin-left: 50px;
    @media screen and (max-width: 767px) {
        width: 100%;
        margin-left: 0;
        margin-top: 15px;

        height: 47px;
        font-size: 16px;
        line-height: 16px;
    }
}

.Home-ukHeroImage {
    @media screen and (max-width: 767px) {
        display: none;
    }
}
