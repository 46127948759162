$primaryColor: #42f9ff;
$primaryColorDark: #20bfb8;
$secondaryColor: #6c31bc;
$transitionTiming: cubic-bezier(0.4, 0, 0.2, 1);
$transitionDuration: 250ms;
$breakpoints: (
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
);
