.Home-conclusion {
    background-color: #fff;
    padding-top: 136px;
    padding-bottom: 0;

    .Home-sectionContentHeadline {
        margin-bottom: 40px;
    }
}

.Home-cardColumn {
    display: inline-flex;
    align-items: flex-end;
    justify-content: center;

    @media screen and (max-width: 768px) {
        padding: 0 4px;
    }
}

.hide-tablet {
    @media screen and (max-width: 767px) {
        display: none;
    }
}

.Home-cardColumn-Large {
    @media screen and (min-width: 1024px) {
        width: 25%;
        .Home-cardImage {
            max-height: 380px;
        }
    }
}

.Home-cardColumn-Medium {
    @media screen and (min-width: 1024px) {
        width: 22%;
        .Home-cardImage {
            max-height: 350px;
        }
    }
}

.Home-cardColumn-Small {
    @media screen and (min-width: 1024px) {
        width: 20%;
        .Home-cardImage {
            max-height: 300px;
        }
    }
}

.Home-cardImages {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 94px;
    @media screen and (max-width: 768px) {
        margin-top: 54px;
    }
}

.Home-cardImage {
    max-width: 90%;
    margin-bottom: -12px;
    @media screen and (max-width: 768px) {
        max-width: 100%;
    }
}
