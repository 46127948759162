.Home-ukTechnology {
    &.Home-section {
        padding-top: 0;
        padding-bottom: 20px;
    }
    @media screen and (max-width: 767px) {
        padding-top: 50px !important;
        padding-bottom: 50px !important;
        min-height: 0;
    }

    .Home-hero-ukTitle {
        @media screen and (max-width: 767px) {
            font-size: 26px;
            line-height: 26px;
            text-align: left;
            max-width: 90%;
        }

        span {
            font-size: 60px;
            @media screen and (max-width: 767px) {
                font-size: 26px;
            }
        }
    }

    .Home-hero-ukCtaRow {
        @media screen and (max-width: 767px) {
            display: none;
        }
    }
}
