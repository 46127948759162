@tailwind base;
@tailwind components;
@layer components {
  @media screen and (max-width: 959.95px) {
    .container {
      max-width: 100%;
    }
  }
}
html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

strong,
b {
  font-weight: 700;
}

body {
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  background-color: #fafafa;
}

body::backdrop {
  background-color: #fafafa;
}

.page__header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100;
  height: 72px;
  padding: 0 24px;
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.14), 0 3px 1px rgba(0, 0, 0, 0.12), 0 1px 5px rgba(0, 0, 0, 0.2);
}
.page__header:before {
  content: "";
  background: linear-gradient(106.54deg, #140078 -4.67%, #6c31bc 112.32%);
  transition: opacity 250ms ease-in-out;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 1;
}
.page__header--transparent:not(.page__header--scrolled):before {
  opacity: 0;
}
.page__header--no-shadow {
  box-shadow: none;
}
.page__header--scrolled {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.14), 0 3px 1px rgba(0, 0, 0, 0.12), 0 1px 5px rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 599.95px) {
  .page__header {
    padding: 0 16px 0 16px;
    height: 60px;
    align-items: center;
  }
}
.page__header__brand {
  display: block;
  padding: 9px 24px 9px 0;
}
@media screen and (max-width: 1279.95px) {
  .page__header__brand {
    padding: 12px 0px 12px 40px;
    display: flex;
  }
}
@media screen and (max-width: 599.95px) {
  .page__header__brand-holder {
    width: 100%;
  }
}
.page__header__brand-image {
  width: auto;
  height: 48px;
}
@media screen and (max-width: 599.95px) {
  .page__header__brand-image {
    height: 36px;
  }
}
.page__header__nav, .page__header__nav-holder {
  display: flex;
}
@media screen and (max-width: 959.95px) {
  .page__header__nav, .page__header__nav-holder {
    display: none;
  }
}
.page__header__nav-holder {
  align-items: stretch;
}
.page__header__nav-item {
  position: relative;
  overflow: hidden;
  padding: 0 18px;
  font-weight: 500;
  letter-spacing: 0.35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.page__header__nav-item:after {
  content: "";
  display: block;
  width: calc(100% - 36px);
  height: 4px;
  border-radius: 2px 2px 0 0;
  position: absolute;
  transform: translate3d(0, 4px, 0) scale(0.5, 1);
  bottom: 0;
  left: 18px;
  background-color: #42f9ff;
  opacity: 0.5;
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.page__header__nav-item--active:after, .page__header__nav-item:hover:after {
  transform: translate3d(0, 0, 0) scale(1, 1);
}
.page__header__nav-item--active {
  color: #42f9ff;
}
.page__header__nav-item--active:after {
  opacity: 1;
}
.page__header__nav-item:hover:after {
  background-color: #fff;
  opacity: 0.8;
}
.page__header__auth {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  min-width: 130px;
}
.page__header__drawer {
  display: none;
  color: #fff;
  width: 1px;
  margin-left: -1px;
}
@media screen and (max-width: 599.95px) {
  .page__header__drawer {
    display: inline-flex;
    align-items: center;
  }
}

.page__content,
.page__body {
  display: flex;
  flex-direction: column;
}

.page__body {
  min-height: 100vh;
}

.page__content {
  flex: 1 1 auto;
}
@media screen and (max-width: 767.95px) {
  .page__content {
    background: #ffffff;
  }
}

.Footer-root {
  background-color: #2e2c35;
  padding: 50px 0 42px 0;
}
@media screen and (max-width: 959px) {
  .Footer-root {
    padding: 64px 32px 50px;
  }
}

.Footer-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
@media screen and (max-width: 959px) {
  .Footer-container {
    flex-direction: column;
  }
}

.Footer-column {
  display: inline-flex;
  max-width: 25%;
  flex-direction: column;
}
@media screen and (max-width: 959px) {
  .Footer-column {
    max-width: 100%;
    padding-bottom: 24px;
  }
}

.Footer-brand {
  display: inline-flex;
  font-size: 0;
  margin-bottom: 28px;
  margin-top: -14px;
}

.Footer-copy {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: rgba(255, 255, 255, 0.8);
}

.Footer-columnHeadline {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 8px;
}

.Footer-columnLink {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #fff;
  margin-bottom: 8px;
}

.Footer-socialLinks {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.Footer-AppsLinks {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}
.Footer-AppsLinks img {
  width: 150px;
}

.Footer-socialLink {
  display: inline-flex;
  margin-left: 4px;
  margin-right: 4px;
}

.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 24px 0;
}
.pagination__limit {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  @apply pr-10;
}
@media screen and (max-width: 959.95px) {
  .pagination__limit {
    padding: 0;
  }
}
.pagination__limit__label, .pagination__limit__select {
  font-size: 14px;
  line-height: 14px;
  color: inherit;
}
.pagination__limit__label option, .pagination__limit__select option {
  @apply text-black;
}
.pagination__limit__select {
  margin-left: 8px;
  background-color: transparent;
  border: none;
  text-align: right;
}
.pagination__navigation {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
}
.pagination__navigation__pages {
  margin-right: 7px;
}
.pagination__navigation__button {
  display: inline-flex;
  padding: 2px;
  color: inherit;
  position: relative;
  margin: 3px;
}
.pagination__navigation__button__disabled {
  pointer-events: none;
}
.pagination__navigation__button:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: currentColor;
  opacity: 0;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.pagination__navigation__button, .pagination__navigation__button:after {
  width: 28px;
  height: 28px;
  border-radius: 14px;
}
.pagination__navigation__button:hover:after {
  opacity: 0.2;
}
.pagination__navigation__button:active:after {
  opacity: 0.4;
}

.Home-hero {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.Home-heroContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  flex: 1 1 auto;
  position: relative;
  z-index: 3;
}

@media screen and (max-width: 767px) {
  .Home-heroContent {
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0 24px 94px;
  }

  .Home-heroContent .container {
    padding: 0;
  }

  .Home-heroContent .Home-buttonCta {
    width: 100%;
  }
}
.Home-heroTitle,
.Home-heroSubtitle {
  color: #fff;
}

.Home-heroTitle,
.Home-heroSubtitle,
.Home-heroCta {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
}

.Home-heroTitle {
  font-weight: 900;
  font-size: 62px;
  line-height: 68px;
  letter-spacing: -0.5px;
  max-width: 800px;
  margin-bottom: 24px;
}

@media screen and (max-width: 767px) {
  .Home-heroTitle {
    font-weight: 900;
    font-size: 40px;
    line-height: 47px;
  }
}
.Home-heroSubtitle {
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.15px;
  margin-bottom: 46px;
}

@media screen and (max-width: 767px) {
  .Home-heroSubtitle {
    font-size: 16px;
    line-height: 24px;
  }
}
.Home-videoHolder,
.Home-videoOverlay,
.Home-video {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.Home-video {
  object-fit: cover;
  object-position: center center;
}

.Home-videoOverlay {
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 3;
}

.Home-poweredBy {
  position: absolute;
  z-index: 3;
  bottom: 24px;
  right: 24px;
}

@media screen and (max-width: 767px) {
  .Home-poweredBy {
    display: none;
  }
}
.page--home .page__header {
  position: sticky;
  left: 0;
  right: 0;
}

.Home-sectionContentCaption,
.Home-sectionContentHeadline,
.Home-sectionContentText {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  max-width: 600px;
}

.Home-sectionContentCaption,
.Home-sectionContentHeadline,
.Home-sectionContentText {
  color: #251f33;
}

.Home-sectionContentCaption,
.Home-sectionContentHeadline {
  margin-bottom: 16px;
}

.Home-sectionContentCaption {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

@media screen and (max-width: 599px) {
  .Home-sectionContentCaption {
    font-size: 14px;
    line-height: 22px;
  }
}
.Home-sectionContentHeadline {
  font-weight: 900;
  font-size: 40px;
  line-height: 48px;
}

@media screen and (max-width: 599px) {
  .Home-sectionContentHeadline {
    font-size: 24px;
    line-height: 32px;
  }
}
.Home-sectionContentText {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin-bottom: 40px;
}

.Home-buttonCta {
  padding: 14px 24px;
  background-color: #20bfb8;
  border-radius: 28px;
  display: inline-block;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.14), 0 2px 1px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.2);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.35px;
  color: #fff;
  text-transform: uppercase;
  transition: background-color 0.2s ease-in-out;
  text-align: center;
}

.Home-buttonCta:hover {
  background-color: #35ddd6;
}

.Home-buttonCta-darkBg {
  padding: 14px 24px;
  background-color: #42e8e0;
  border-radius: 28px;
  display: inline-block;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.14), 0 2px 1px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.2);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.35px;
  color: rgba(0, 0, 0, 0.87);
  text-transform: uppercase;
  transition: background-color 0.2s ease-in-out;
  text-align: center;
}

.Home-buttonCta-darkBg:hover {
  background-color: #00fff3;
}

.Home-sectionContainer {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (min-width: 1600px) {
  .Home-sectionContainer {
    max-width: 1440px;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1599px) {
  .Home-sectionContainer {
    max-width: 100%;
  }
}
.Home-section {
  padding: 180px 0;
  position: relative;
  z-index: 1;
  background-color: #fff;
  overflow: hidden;
}

.Home-sectionLayers {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
}

.Home-sectionLayer {
  position: absolute;
}

@media screen and (max-width: 959px) {
  .Home-sectionLayer {
    opacity: 0.5;
  }
}
.Home-sectionLayer--topLeft {
  left: 64px;
  top: 92px;
}

.Home-sectionLayer--topRight {
  right: 64px;
  top: 92px;
}

.Home-sectionLayer--bottomRight {
  right: 64px;
  bottom: 92px;
}

.Home-sectionLayer--bottomRight.Home-sectionLayer--offset {
  right: -5%;
  bottom: -20%;
}

.Home-sectionLayer--bottomLeft {
  left: 64px;
  bottom: 92px;
}

.Home-sectionLayer--bottomLeft.Home-sectionLayer--offset {
  left: -25%;
  bottom: -30%;
}

.Home-sectionColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 40px;
}

.Home-sectionColumn--half {
  width: 50%;
}

@media screen and (max-width: 1199px) {
  .Home-sectionColumn--half {
    width: 100%;
  }
}
@media screen and (max-width: 959px) {
  .Home-sectionColumn--half {
    padding: 0 24px;
  }
}
@media screen and (max-width: 599px) {
  .Home-sectionColumn--half {
    padding: 0 14px;
  }
}
.Home-sectionColumn--third {
  width: 33.333334%;
}

@media screen and (max-width: 1279px) {
  .Home-sectionColumn--third {
    width: 50%;
  }
}
@media screen and (max-width: 959px) {
  .Home-sectionColumn--third {
    padding: 0 24px;
  }
}
@media screen and (max-width: 599px) {
  .Home-sectionColumn--third {
    padding: 0 14px;
  }
}
.Home-sectionColumn--center-center,
.Home-sectionContainer--center-center {
  align-items: center;
  justify-content: center;
}

.text-center {
  text-align: center;
}

.Home-sectionImageHolder {
  display: block;
  width: 100%;
  padding-bottom: 60%;
  position: relative;
}

@media screen and (max-width: 1199px) {
  .Home-sectionImageHolder {
    margin-bottom: 48px;
  }
}
.Home-sectionImage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0 auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  object-position: center center;
  border-radius: 12px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.14), 0 1px 18px rgba(0, 0, 0, 0.12), 0 3px 5px rgba(0, 0, 0, 0.2);
}

.Home-sectionImagePlay {
  position: absolute;
  z-index: 1;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  background-color: rgba(0, 0, 0, 0.71);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .Home-sectionImagePlay {
    width: 64px;
    height: 64px;
  }
}
.Home-sectionImagePlay .material-icons {
  font-size: 64px;
  width: 64px;
  height: 64px;
  color: #fff;
}

@media screen and (max-width: 767px) {
  .Home-sectionImagePlay .material-icons {
    width: 32px;
    height: 32px;
    font-size: 32px;
  }
}
@media screen and (max-width: 767px) {
  .Home-about {
    padding: 48px 0;
  }

  .Home-about .Home-sectionLayer--topLeft {
    display: none;
  }

  .Home-about .Home-sectionImageHolder {
    margin-top: 48px;
    margin-bottom: 0;
  }

  .Home-about .Home-sectionContainer {
    flex-direction: column-reverse;
  }
}
.Home-availableCards {
  background-color: #f4f4fb;
  padding-top: 95px;
  padding-bottom: 110px;
}

.Home-availableCards .Home-sectionContentText {
  margin-bottom: 0;
}

.Home-availableCards .Home-sectionContentHeadline {
  font-size: 24px;
}

.Home-availableCardsColumn {
  width: 25%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

@media screen and (max-width: 768px) {
  .Home-availableCardsColumn {
    width: 50%;
  }
}
.Home-availableCardsImages {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 94px;
  flex-wrap: wrap;
  flex-flow: row;
}

@media screen and (max-width: 768px) {
  .Home-availableCardsImages {
    margin-top: 54px;
    flex-flow: wrap;
  }
}
.Home-availableCardsImage {
  max-width: 8em;
  max-height: 5em;
  min-height: 3em;
  margin: auto;
}

@media screen and (max-width: 600px) {
  .Home-availableCardsImage {
    max-width: 7em;
    max-height: 4em;
  }
}
.Home-pricing {
  background-color: #261d39;
  padding-top: 108px;
  padding-bottom: 145px;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 1280px) {
  .Home-pricing {
    padding-bottom: 100px;
  }
}
@media screen and (max-width: 699px) {
  .Home-pricing {
    padding-top: 84px;
  }
}
.Home-pricing .Home-sectionContentCaption {
  color: #eee;
}

.Home-pricing .Home-sectionContentHeadline {
  color: #fff;
}

.Home-pricing .Home-sectionContentHeadline {
  font-weight: 900;
  font-size: 34px;
  line-height: 40px;
  max-width: 100%;
}

@media screen and (max-width: 699px) {
  .Home-pricing .Home-sectionContentHeadline {
    font-size: 24px;
    line-height: 32px;
  }
}
.Home-pricing .Home-sectionColumn {
  width: 100%;
}

.Home-pricing .Home-sectionContainer {
  flex-direction: column;
  padding: 0;
}

@media screen and (max-width: 1264px) {
  .Home-pricing .Home-sectionContainer {
    width: 100%;
    max-width: 100%;
  }
}
.Home-pricingBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
  object-position: center;
  z-index: -1;
}

.Home-priceRange {
  width: 100%;
}

.Home-pricesHolder {
  max-width: 100%;
}

.Home-prices {
  max-width: calc(100% - 68px);
  display: flex;
  margin: 64px 34px 0;
}

@media screen and (max-width: 699px) {
  .Home-prices {
    margin-top: 32px;
  }
}
@media screen and (max-width: 699px) {
  .Home-prices .Home-priceColumn {
    width: 100%;
    margin: 0;
  }
}
.Home-prices .Home-priceColumn .Home-submissionCtaContainer {
  margin: 0 auto;
}

.Home-prices .Home-priceColumn .Home-buttonCta {
  margin: 0 0 24px;
}

@media screen and (min-width: 1024px) {
  .Home-details {
    padding: 0px 0px 90px 0px;
  }
}
@media screen and (max-width: 1199px) {
  .Home-details {
    padding-top: 72px;
    padding-bottom: 92px;
  }
}
@media screen and (min-width: 1024px) {
  .Home-details .Home-sectionColumn-first {
    padding: 300px 0px 180px 0px;
  }
}
@media screen and (min-width: 1024px) {
  .Home-details .Home-sectionColumn-second {
    padding: 90px 0px;
  }
}
@media screen and (max-width: 1199px) {
  .Home-details .Home-sectionImageHolder {
    margin-top: 56px;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
@media screen and (min-width: 1024px) {
  .Home-details .Home-sectionImageHolder .Home-sectionImage {
    max-width: 600px;
    max-height: 700px;
  }
}
@media screen and (max-width: 1199px) {
  .Home-details .Home-sectionImageHolder .Home-sectionImage {
    position: relative;
    width: 100%;
    object-fit: cover;
    object-position: center top;
  }
}
@media screen and (max-width: 959px) {
  .Home-details .Home-sectionLayers {
    display: none;
  }
}
.Home-features {
  background-color: #f4f4fb;
  padding-top: 86px;
  padding-bottom: 124px;
}

.Home-features .Home-sectionContentHeadline {
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 0;
}

.Home-features .Home-sectionColumn {
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 959px) {
  .Home-features .Home-sectionColumn {
    width: 100%;
  }
}
.Home-featuresGrid {
  margin-top: 50px;
}

.Home-featureColumn {
  padding: 50px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media screen and (max-width: 959px) {
  .Home-featureColumn {
    padding: 24px;
  }
}
.Home-featureTitle,
.Home-featureText {
  max-width: 328px;
}

.Home-featureTitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  margin: 12px 0;
}

.Home-featureText {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
}

.Home-testimonial {
  display: flex;
  padding: 0;
  background-color: #251f33;
  overflow: hidden;
}

.Home-testimonial .Home-sectionColumn {
  padding: 0;
  position: relative;
}

@media screen and (max-width: 1199px) {
  .Home-testimonial {
    flex-direction: column-reverse;
  }

  .Home-testimonial .Home-sectionColumn {
    width: 100% !important;
    padding-bottom: 64%;
  }
}
.Home-testimonialContent {
  max-width: 620px;
  position: relative;
}

.Home-testimonialContentHolder.Home-testimonialContentHolder {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 140px 124px;
  width: 60%;
}

@media screen and (max-width: 1199px) {
  .Home-testimonialContentHolder.Home-testimonialContentHolder {
    align-items: center;
  }
}
@media screen and (max-width: 767px) {
  .Home-testimonialContentHolder.Home-testimonialContentHolder {
    padding: 80px;
  }
}
@media screen and (max-width: 600px) {
  .Home-testimonialContentHolder.Home-testimonialContentHolder {
    padding: 80px 32px;
  }
}
.Home-testimonialImage {
  position: relative;
  width: 100%;
  height: auto;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.14), 0 3px 1px rgba(0, 0, 0, 0.12), 0 1px 5px rgba(0, 0, 0, 0.2);
  z-index: 3;
}

@media screen and (max-width: 1919px) {
  .Home-testimonialImage {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
  }
}
.Home-testimonialQuotes {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.4;
  transform: translate(-43%, -75%);
  z-index: -1;
}

.Home-testimonialHeadline {
  font-weight: 900;
  font-size: 48px;
  line-height: 56px;
  color: #dcc2ff;
  margin-bottom: 24px;
}

.Home-testimonialText {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 42px;
  color: rgba(255, 255, 255, 0.87);
}

.Home-testimonialProfile {
  display: flex;
  align-items: center;
}

.Home-testimonialProfileImage {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.14), 0 3px 1px rgba(0, 0, 0, 0.12), 0 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 42px;
  margin-right: 14px;
}

.Home-testimonialProfileNameHeadline {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #fff;
}

.Home-testimonialProfileNameText {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.7);
}

.Home-conclusion {
  background-color: #fff;
  padding-top: 136px;
  padding-bottom: 0;
}

.Home-conclusion .Home-sectionContentHeadline {
  margin-bottom: 40px;
}

.Home-cardColumn {
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .Home-cardColumn {
    padding: 0 4px;
  }
}
@media screen and (max-width: 767px) {
  .hide-tablet {
    display: none;
  }
}
@media screen and (min-width: 1024px) {
  .Home-cardColumn-Large {
    width: 25%;
  }

  .Home-cardColumn-Large .Home-cardImage {
    max-height: 380px;
  }
}
@media screen and (min-width: 1024px) {
  .Home-cardColumn-Medium {
    width: 22%;
  }

  .Home-cardColumn-Medium .Home-cardImage {
    max-height: 350px;
  }
}
@media screen and (min-width: 1024px) {
  .Home-cardColumn-Small {
    width: 20%;
  }

  .Home-cardColumn-Small .Home-cardImage {
    max-height: 300px;
  }
}
.Home-cardImages {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 94px;
}

@media screen and (max-width: 768px) {
  .Home-cardImages {
    margin-top: 54px;
  }
}
.Home-cardImage {
  max-width: 90%;
  margin-bottom: -12px;
}

@media screen and (max-width: 768px) {
  .Home-cardImage {
    max-width: 100%;
  }
}
.Home-searchCards {
  background-color: #5b35ab;
  padding-bottom: 145px;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 1280px) {
  .Home-searchCards {
    padding-bottom: 50px;
  }
}
@media screen and (max-width: 699px) {
  .Home-searchCards {
    padding-top: 50px;
  }
}
.Home-searchCards .Home-sectionContentHeadline {
  font-weight: 900;
  font-size: 40px;
  line-height: 40px;
  max-width: 100%;
}

@media screen and (max-width: 699px) {
  .Home-searchCards .Home-sectionContentHeadline {
    font-size: 24px;
    font-weight: 900;
    line-height: 38px;
    letter-spacing: 0px;
    text-align: left;
  }
}
.Home-searchCards .Home-sectionContentCaption,
.Home-searchCards .Home-sectionContentHeadline {
  color: white;
  width: 550px;
}

@media screen and (max-width: 699px) {
  .Home-searchCards .Home-sectionContentCaption {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 1.5px;
    text-align: left;
  }

  .Home-searchCards .Home-sectionColumn--half--right {
    padding: 0 !important;
  }
}
.Home-searchCards .Home-sectionColumn--half--left {
  margin-top: 30px;
}

.Home-searchCardsBackground {
  position: absolute;
  height: 100%;
  width: 75%;
  top: 75px;
  right: 0;
  bottom: 0;
  z-index: -1;
}

@media screen and (max-width: 699px) {
  .Home-searchCardsBackground {
    width: 100%;
    height: 314px;
    top: 50%;
  }
}
.Home-searchCards-search-form {
  width: 89%;
}

@media screen and (max-width: 699px) {
  .Home-searchCards-search-form {
    width: 100%;
  }
}
.ReferralHome-sectionContentCaption,
.ReferralHome-sectionContentHeadline,
.ReferralHome-sectionContentText {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  max-width: 600px;
}

.ReferralHome-sectionContentCaption,
.ReferralHome-sectionContentHeadline,
.ReferralHome-sectionContentText {
  color: #251f33;
}

.ReferralHome-sectionContentCaption,
.ReferralHome-sectionContentHeadline {
  margin-bottom: 16px;
}

.ReferralHome-sectionContentCaption {
  font-weight: 500;
  font-size: 16px !important;
  color: #251f33;
  margin-top: 25px;
  line-height: 24px;
  letter-spacing: 1.5px;
  padding: 0px;
  text-transform: uppercase;
}

@media screen and (max-width: 599px) {
  .ReferralHome-sectionContentCaption {
    font-size: 14px;
    line-height: 22px;
  }
}
.ReferralHome-sectionContentCaptionSteps {
  font-weight: 900;
  text-align: center;
  font-size: 32px;
  line-height: 38px;
}

@media screen and (max-width: 767px) {
  .ReferralHome-sectionContentCaptionSteps {
    font-size: 28px;
    line-height: 32.81px;
  }
}
.ReferralHome-sectionContentHeadline {
  font-weight: 900;
  font-size: 40px;
  line-height: 48px;
}

@media screen and (max-width: 599px) {
  .ReferralHome-sectionContentHeadline {
    font-size: 24px;
    line-height: 32px;
  }
}
.ReferralHome-sectionContentText {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.ReferralHome-sectionContainer {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (min-width: 1600px) {
  .ReferralHome-sectionContainer {
    max-width: 1440px;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1599px) {
  .ReferralHome-sectionContainer {
    max-width: 100%;
  }
}
.ReferralHome-sectionContainerAbout {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (min-width: 1600px) {
  .ReferralHome-sectionContainerAbout {
    max-width: 1440px;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1599px) {
  .ReferralHome-sectionContainerAbout {
    max-width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .ReferralHome-sectionContainerAbout {
    flex-direction: column-reverse;
  }
}
.ReferralHome-communityColumn {
  padding: 24px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media screen and (max-width: 959px) {
  .ReferralHome-communityColumn {
    padding: 24px;
  }
}
.ReferralHome-section {
  padding: 180px 0;
  position: relative;
  z-index: 1;
  background-color: #fff;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .ReferralHome-section {
    padding: 80px 0;
    z-index: 0;
    padding-top: 75%;
  }
}
@media screen and (max-width: 500px) {
  .ReferralHome-section {
    padding-top: 125%;
  }
}
@media screen and (max-width: 400px) {
  .ReferralHome-section {
    padding-top: 140%;
  }
}
@media screen and (max-width: 360px) {
  .ReferralHome-section {
    padding-top: 145%;
  }
}
.ReferralHome-sectionCommunity {
  padding: 70px 0;
  position: relative;
  z-index: 1;
  background-color: #fff;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .ReferralHome-sectionCommunity {
    padding-top: 30px;
  }
}
.ReferralHome-sectionCommunityGrid {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
}

.ReferralHome-sectionCommunityColumn {
  padding: 50px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media screen and (max-width: 959px) {
  .ReferralHome-sectionCommunityColumn {
    padding: 24px;
  }
}
.ReferralHome-button {
  background-color: #20bfb8;
  color: white;
  border-radius: 28px;
  padding: 15px 40px;
  text-transform: uppercase;
  margin-top: 5%;
  font-weight: 500;
  font-size: 14px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.14), 0 2px 1px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.2);
}

.ReferralHome-button:hover {
  background-color: #42e8e0;
}

@media screen and (max-width: 767px) {
  .ReferralHome-button {
    margin-top: 8%;
  }
}
.ReferralHome-buttonAbout {
  margin-left: 5%;
}

@media screen and (max-width: 767px) {
  .ReferralHome-buttonAbout {
    margin-left: -5%;
    margin-top: 12%;
    margin-bottom: 14%;
  }
}
.ReferralHome-sectionLayers {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
}

.ReferralHome-sectionLayer {
  position: absolute;
}

@media screen and (max-width: 959px) {
  .ReferralHome-sectionLayer {
    opacity: 0.5;
  }
}
.ReferralHome-sectionLayer--topLeft {
  left: 64px;
  top: 92px;
}

.ReferralHome-sectionLayer--topRight {
  right: 64px;
  top: 92px;
}

.ReferralHome-sectionLayer--bottomRight {
  right: 64px;
  bottom: 92px;
}

.ReferralHome-sectionLayer--bottomRight.ReferralHome-sectionLayer--offset {
  right: -5%;
  bottom: -20%;
}

.ReferralHome-sectionLayer--bottomLeft {
  left: 64px;
  bottom: 92px;
}

.ReferralHome-sectionLayer--bottomLeft.ReferralHome-sectionLayer--offset {
  left: -25%;
  bottom: -30%;
}

.ReferralHome-sectionColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.ReferralHome-sectionColumn--videoDiv {
  position: relative;
  cursor: pointer;
}

.ReferralHome-sectionColumn--half {
  width: 50%;
}

@media screen and (max-width: 1199px) {
  .ReferralHome-sectionColumn--half {
    width: 100%;
  }
}
@media screen and (max-width: 959px) {
  .ReferralHome-sectionColumn--half {
    padding: 0 24px;
  }
}
@media screen and (max-width: 599px) {
  .ReferralHome-sectionColumn--half {
    padding: 0 14px;
  }
}
.ReferralHome-sectionColumn--third {
  width: 33.333334%;
}

@media screen and (max-width: 1279px) {
  .ReferralHome-sectionColumn--third {
    width: 50%;
  }
}
@media screen and (max-width: 959px) {
  .ReferralHome-sectionColumn--third {
    padding: 0 24px;
    width: 100%;
  }
}
@media screen and (max-width: 599px) {
  .ReferralHome-sectionColumn--third {
    padding: 0 14px;
    width: 100%;
  }
}
.ReferralHome-sectionColumn--fourth {
  width: 25%;
}

.ReferralHome-sectionColumn--fourth:hover {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 1279px) {
  .ReferralHome-sectionColumn--fourth {
    width: 50%;
  }
}
@media screen and (max-width: 959px) {
  .ReferralHome-sectionColumn--fourth {
    padding: 0 24px;
  }
}
@media screen and (max-width: 767px) {
  .ReferralHome-sectionColumn--fourth {
    width: 50%;
    padding: 0;
  }
}
@media screen and (max-width: 599px) {
  .ReferralHome-sectionColumn--fourth {
    padding: 0 4px;
  }
}
.ReferralHome-communityIcon {
  width: 348px;
  height: 348px;
  padding: 0px 4px 0px;
  margin-top: 5px;
  margin-bottom: 5px;
  object-fit: cover;
}

@media screen and (max-width: 767px) {
  .ReferralHome-communityIcon {
    width: 100%;
    width: 300px;
    height: 300px;
    margin: 0;
  }
}
@media screen and (max-width: 599px) {
  .ReferralHome-communityIcon {
    height: 179px;
    padding: 4px 0px;
    margin: 0;
  }
}
.ReferralHome-sectionColumn--center-center,
.ReferralHome-sectionContainer--center-center {
  align-items: center;
  justify-content: center;
}

.text-center {
  text-align: center;
}

.ReferralHome-sectionImageHolder {
  display: block;
  width: 100%;
  padding-bottom: 60%;
  position: relative;
}

@media screen and (max-width: 1199px) {
  .ReferralHome-sectionImageHolder {
    margin-bottom: 48px;
  }
}
.ReferralHome-sectionImage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0 auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  object-position: center center;
  border-radius: 12px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.14), 0 1px 18px rgba(0, 0, 0, 0.12), 0 3px 5px rgba(0, 0, 0, 0.2);
}

.ReferralHome-sectionImagePlay {
  position: absolute;
  z-index: 1;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  background-color: rgba(0, 0, 0, 0.71);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .ReferralHome-sectionImagePlay {
    width: 64px;
    height: 64px;
  }
}
.ReferralHome-sectionImagePlay .material-icons {
  font-size: 64px;
  width: 64px;
  height: 64px;
  color: #fff;
}

@media screen and (max-width: 767px) {
  .ReferralHome-sectionImagePlay .material-icons {
    width: 32px;
    height: 32px;
    font-size: 32px;
  }
}
.ReferralHome-signup {
  background-color: #261d39;
  padding-top: 108px;
  padding-bottom: 145px;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 1280px) {
  .ReferralHome-signup {
    padding-bottom: 100px;
  }
}
@media screen and (max-width: 767px) {
  .ReferralHome-signup {
    padding-top: 20px;
    padding-bottom: 0;
    height: 600px;
  }
}
.ReferralHome-signup .ReferralHome-sectionContentCaption {
  color: #eee;
}

.ReferralHome-signup .ReferralHome-sectionContentHeadline {
  color: #fff;
}

.ReferralHome-signup .ReferralHome-sectionContentHeadline {
  font-weight: 900;
  font-size: 34px;
  line-height: 40px;
  max-width: 100%;
}

@media screen and (max-width: 699px) {
  .ReferralHome-signup .ReferralHome-sectionContentHeadline {
    font-size: 24px;
    line-height: 32px;
  }
}
.ReferralHome-signup .ReferralHome-sectionColumn {
  width: 100%;
}

.ReferralHome-signup .ReferralHome-sectionContainer {
  flex-direction: column;
  padding: 0;
}

@media screen and (max-width: 1264px) {
  .ReferralHome-signup .ReferralHome-sectionContainer {
    width: 100%;
    max-width: 100%;
  }
}
.ReferralHome-main {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
}

@media screen and (max-width: 767px) {
  .ReferralHome-main {
    display: grid;
    padding: 20px;
  }
}
.ReferralHome-mainRight {
  max-width: 455px;
  background-color: white;
  border-radius: 22px;
}

@media screen and (max-width: 767px) {
  .ReferralHome-mainRight {
    width: 100%;
    z-index: 3;
    box-shadow: 0 8px 10px -2px #e5e5e5;
  }
}
.ReferralHome-iconDiv {
  background: #e1bbff;
  padding: 14px 8px;
}

.ReferralHome-referralName {
  display: flex;
  border: 2px solid #e1bbff;
  border-radius: 7px;
  max-width: 85%;
  margin-bottom: 24px;
  width: fit-content;
  align-items: center;
}

.ReferralHome-referralName span {
  color: #e1bbff;
  font-size: 12px;
  text-align: left;
  text-transform: uppercase;
  margin: auto;
  padding: 16px 12px;
}

@media screen and (max-width: 767px) {
  .ReferralHome-referralName span {
    padding: 8px 8px;
  }
}
@media screen and (max-width: 767px) {
  .ReferralHome-referralName {
    max-width: 100%;
  }
}
.ReferralHome-sectionTitle {
  font-weight: 900;
  font-size: 56px;
  line-height: 68px;
  letter-spacing: -0.5px;
  max-width: 684px;
  margin-bottom: 24px;
}

.ReferralHome-sectionTitle span {
  color: #42e8e0;
}

@media screen and (max-width: 767px) {
  .ReferralHome-sectionTitle {
    font-weight: 900;
    font-size: 36px;
    line-height: 44px;
  }
}
.ReferralHome-sectionSubtitle {
  font-size: 20px;
  line-height: 28px;
  max-width: 630px;
  letter-spacing: 0.15px;
  margin-bottom: 16px;
}

@media screen and (max-width: 767px) {
  .ReferralHome-sectionSubtitle {
    font-size: 16px;
    line-height: 24px;
  }
}
.ReferralHome-sectionCaption {
  font-size: 14px;
  line-height: 28px;
  max-width: 630px;
  letter-spacing: 0.15px;
  margin-bottom: 46px;
}

@media screen and (max-width: 767px) {
  .ReferralHome-sectionCaption {
    font-size: 16px;
    line-height: 24px;
  }
}
.ReferralHome-sectionTitle,
.ReferralHome-sectionSubtitle,
.ReferralHome-sectionCaption {
  color: #fff;
}

.ReferralHome-pricingBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
  object-position: center;
  z-index: -1;
}

.ReferralHome-priceRange {
  width: 100%;
}

.ReferralHome-pricesHolder {
  max-width: 100%;
}

.ReferralHome-prices {
  max-width: calc(100% - 68px);
  display: flex;
  margin: 64px 34px 0;
}

@media screen and (max-width: 699px) {
  .ReferralHome-prices {
    margin-top: 32px;
  }
}
@media screen and (max-width: 699px) {
  .ReferralHome-prices .ReferralHome-priceColumn {
    width: 100%;
    margin: 0;
  }
}
.ReferralHome-prices .ReferralHome-priceColumn .ReferralHome-submissionCtaContainer {
  margin: 0 auto;
}

@media screen and (max-width: 1199px) {
  .ReferralHome-details {
    padding-top: 72px;
    padding-bottom: 92px;
  }
}
@media screen and (max-width: 1199px) {
  .ReferralHome-details .ReferralHome-sectionImageHolder {
    margin-top: 56px;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .ReferralHome-details .ReferralHome-sectionImageHolder .ReferralHome-sectionImage {
    position: relative;
    width: 100%;
    object-fit: cover;
    object-position: center top;
  }
}
@media screen and (max-width: 959px) {
  .ReferralHome-details .ReferralHome-sectionLayers {
    display: none;
  }
}
.ReferralHome-features {
  background-color: #f4f4fb;
  padding-top: 114px;
  padding-bottom: 0px;
}

@media screen and (max-width: 767px) {
  .ReferralHome-features {
    padding-top: 50px;
  }
}
.ReferralHome-features .ReferralHome-sectionContentHeadline {
  font-size: 40px;
  line-height: 28px;
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  .ReferralHome-features .ReferralHome-sectionContentHeadline {
    font-size: 28px;
  }
}
.ReferralHome-features .ReferralHome-sectionColumn {
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 959px) {
  .ReferralHome-features .ReferralHome-sectionColumn {
    width: 100%;
  }
}
.ReferralHome-steps {
  background-color: #f4f4fb;
  padding-top: 114px;
  padding-bottom: 120px;
}

@media screen and (max-width: 767px) {
  .ReferralHome-steps {
    padding-top: 72px;
    padding-bottom: 74px;
  }
}
.Numbering-section {
  padding: 15px;
}

.Numbering {
  margin: auto;
  border: 2px solid #5022a7;
  border-radius: 50%;
  padding: 8px;
  height: 40px;
  width: 40px;
  display: block;
  text-align: center;
  color: #5022a7;
}

.Numbering-title {
  margin-top: 4%;
  margin-bottom: 4%;
  font-size: 24px;
  font-weight: 500;
  color: #000000de;
  text-align: center;
  line-height: 24px;
}

.Numbering-subtitle {
  margin-top: 10%;
  font-size: 16px;
  font-weight: 400;
  width: 260px !important;
  line-height: 24px;
  text-align: center;
  color: #000000de;
}

.ReferralHome-featuresGrid {
  margin-top: 5px;
}

.ReferralHome-featureColumnSteps {
  padding: 50px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media screen and (max-width: 959px) {
  .ReferralHome-featureColumnSteps {
    padding: 24px;
  }
}
.ReferralHome-featureIcon {
  min-width: 235px;
  height: 225px;
  object-fit: cover;
}

.ReferralHome-featureColumn {
  margin-bottom: 10px;
  width: 348px;
  height: 348px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: inline-block;
}

@media screen and (max-width: 767px) {
  .ReferralHome-featureColumn {
    margin-bottom: 2px;
    padding: 0;
    display: block;
    width: 300px;
    max-height: 300px;
  }
}
@media screen and (max-width: 599px) {
  .ReferralHome-featureColumn {
    width: 179px;
    height: 179px;
    margin: 4px;
  }
}
.Home-featureTitle,
.Home-featureText {
  max-width: 328px;
}

.Home-featureTitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  margin: 12px 0;
}

.Home-featureText {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
}

.ReferralHome-conclusion {
  background-color: #f4f4fb;
  padding-top: 136px;
  padding-bottom: 0;
}

.ReferralHome-conclusion .ReferralHome-sectionContentHeadline {
  margin-bottom: 40px;
}

.ReferralHome-cardColumn {
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .ReferralHome-cardColumn {
    padding: 0 4px;
  }
}
@media screen and (max-width: 767px) {
  .hide-tablet {
    display: none;
  }
}
@media screen and (min-width: 1024px) {
  .ReferralHome-cardColumn-Large {
    width: 25%;
  }

  .ReferralHome-cardColumn-Large .ReferralHome-cardImage {
    max-height: 380px;
  }
}
@media screen and (min-width: 1024px) {
  .ReferralHome-cardColumn-Medium {
    width: 22%;
  }

  .ReferralHome-cardColumn-Medium .ReferralHome-cardImage {
    max-height: 350px;
  }
}
@media screen and (min-width: 1024px) {
  .ReferralHome-cardColumn-Small {
    width: 20%;
  }

  .ReferralHome-cardColumn-Small .ReferralHome-cardImage {
    max-height: 300px;
  }
}
.ReferralHome-cardImages {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 94px;
}

@media screen and (max-width: 768px) {
  .ReferralHome-cardImages {
    margin-top: 54px;
  }
}
.ReferralHome-cardImage {
  max-width: 90%;
  margin-bottom: -12px;
}

@media screen and (max-width: 768px) {
  .ReferralHome-cardImage {
    max-width: 100%;
  }
}
.authentication-view__table-div-left {
  width: 320px;
  display: table-row;
}
@media screen and (max-width: 767.95px) {
  .authentication-view__table-div-left {
    display: none;
  }
}
@media screen and (max-width: 1023.95px) {
  .authentication-view__table-div-left {
    width: 100%;
    height: 100%;
  }
}
.authentication-view__table-div-right {
  width: 320px;
}
@media screen and (max-width: 1023.95px) {
  .authentication-view__table-div-right {
    width: 100%;
    height: 100%;
  }
}
.authentication-view__share {
  background: #f4f4fb;
  border-radius: 8px;
  width: 320px;
  height: 80px;
  padding: 15px;
}
@media screen and (max-width: 1023.95px) {
  .authentication-view__share {
    width: 100%;
    height: 100%;
  }
}
.authentication-view__share__title {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.54);
}
.authentication-view__share__icon {
  color: rgba(0, 0, 0, 0.54);
  padding-top: 10px;
  padding-bottom: 5px;
}
.authentication-view__share__icon:hover {
  cursor: pointer;
}
.authentication-view__ags-population {
  background: #f4f4fb;
  border-radius: 8px;
  width: 320px;
  height: 80px;
  display: flex;
  justify-content: space-between;
  padding: 15px;
}
@media screen and (max-width: 1023.95px) {
  .authentication-view__ags-population {
    width: 100%;
    height: 100%;
    padding: 10px;
  }
}
@media screen and (max-width: 767.95px) {
  .authentication-view__ags-population {
    width: 100%;
    padding: 15px !important;
  }
}
.authentication-view__ags-population__count {
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #251f33;
  padding-top: 10px;
}
.authentication-view__ags-population__title {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.54);
}
.authentication-view__ags-population__icon:hover {
  cursor: pointer;
}
.authentication-view__ags-population__icon {
  display: flex;
}
.authentication-view__ags-population__icon__text {
  margin-top: 2px;
  margin-left: 2px;
  color: #20bfb8;
}
.authentication-view__ags-population__icon .material-icons {
  color: #20bfb8;
}
.authentication-view__right-side {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767.95px) {
  .authentication-view__right-side {
    margin-left: 0px;
  }
}
.authentication-view__left-side {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767.95px) {
  .authentication-view__share {
    display: none;
  }
}
.authentication-view__generated-images {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
.authentication-view__generated-images__actions {
  display: flex;
  justify-content: center;
  align-items: center;
}
.authentication-view__generated-images__text {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
  padding: 20px;
}
.authentication-view__generated-images__front {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 40px 0px;
}
.authentication-view__generated-images__front__image {
  width: 403px;
  height: auto;
  background: #000;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.14)) drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.12)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2));
  border-radius: 8px;
  transform: rotate(90deg);
  margin: 0px;
}
.authentication-view__generated-images__back {
  display: none;
}
.authentication-view__generated-images__back__image {
  width: 20%;
}
.authentication-view__generated-images__buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.authentication-view__generated-images__buttons__front {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #20bfb8;
  background: rgba(32, 191, 184, 0.08);
  border: 1px solid #20bfb8;
  border-radius: 24px;
  padding: 10px 20px;
  margin: 5px;
}
.authentication-view__generated-images__buttons__back {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: rgba(0, 0, 0, 0.54);
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.18);
  border-radius: 24px;
  padding: 10px 20px;
  margin: 5px;
}
.authentication-view__header {
  position: relative;
  overflow: hidden;
  padding-top: 86px;
}
@media screen and (max-width: 767.95px) {
  .authentication-view__header {
    padding-top: 0;
  }
}
.authentication-view__header__overlay {
  z-index: 1;
}
.authentication-view__header__overlay, .authentication-view__header__overlay-background, .authentication-view__header__overlay-color {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: black;
  overflow: hidden;
  background: linear-gradient(rgba(37, 31, 51, 0.7), rgba(37, 31, 51, 0.7));
}
.authentication-view__header__overlay-background {
  z-index: 1;
  object-fit: cover;
  object-position: center center;
}
.authentication-view__header__overlay-color {
  z-index: 3;
}
.authentication-view__header__container {
  position: relative;
  z-index: 1;
  display: flex;
}
.authentication-view__header__content {
  min-height: 142px;
  color: #fff;
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 20px;
}
@media screen and (max-width: 767.95px) {
  .authentication-view__header__content {
    padding-top: 0;
    max-width: 100%;
    margin-bottom: 50%;
    margin-top: 20px;
  }
}
@media screen and (max-width: 599.95px) {
  .authentication-view__header__content {
    padding-left: 0;
    padding-bottom: 14px;
    padding-top: 18px;
  }
}
.authentication-view__header__details {
  width: 100%;
  flex: 1 1 auto;
  padding-right: 14px;
}
.authentication-view__header__certificate {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  margin-bottom: 12px;
}
@media screen and (max-width: 767.95px) {
  .authentication-view__header__certificate {
    margin-bottom: 30px;
    font-size: 12px;
  }
}
.authentication-view__header__share {
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 35px;
  display: none;
}
@media screen and (max-width: 599.95px) {
  .authentication-view__header__share {
    display: flex;
  }
}
.authentication-view__header__heading, .authentication-view__header__subheading {
  color: #fff;
}
.authentication-view__header__heading {
  font-size: 34px;
  line-height: 40px;
  margin-bottom: 6px;
  font-weight: 700;
}
@media screen and (max-width: 599.95px) {
  .authentication-view__header__heading {
    font-size: 24px !important;
  }
}
@media screen and (max-width: 1023.95px) {
  .authentication-view__header__heading {
    font-size: 30px;
  }
}
.authentication-view__header__subheading {
  font-size: 16px;
  line-height: 24px;
}
@media screen and (max-width: 599.95px) {
  .authentication-view__header__subheading {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 1023.95px) {
  .authentication-view__header__subheading {
    font-size: 14px;
  }
}
.authentication-view__header__score {
  display: inline-flex;
  justify-content: flex-end;
}
.authentication-view__header__grade {
  width: 84px;
  height: 90px;
  background: #20bfb8;
  border-radius: 8px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 599.95px) {
  .authentication-view__header__grade {
    padding: 10px 14px;
    min-width: 71px;
    min-height: 82px;
  }
}
.authentication-view__header__grade-label, .authentication-view__header__grade-score {
  text-align: center;
  color: rgba(255, 255, 255, 0.87);
}
.authentication-view__header__grade-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #ffffff;
  margin-bottom: 6px;
  text-transform: uppercase;
}
@media screen and (max-width: 599.95px) {
  .authentication-view__header__grade-label {
    font-size: 12px;
    margin-bottom: 0;
  }
}
.authentication-view__header__grade-score {
  font-weight: 900;
  font-size: 44px;
  line-height: 52px;
  text-align: center;
  color: #ffffff;
}
@media screen and (max-width: 599.95px) {
  .authentication-view__header__grade-score {
    font-size: 40px;
  }
}
@media screen and (max-width: 767.95px) {
  .authentication-view__header .authentication-view__card-holder {
    display: none;
  }
}
.authentication-view__card .container {
  width: 546px;
}
.authentication-view__card-holder {
  width: 92%;
}
@media screen and (max-width: 767.95px) {
  .authentication-view__card-holder {
    width: 100%;
  }
}
.authentication-view__card-image {
  width: 50%;
  border-radius: 4%;
  margin: auto;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.14), 0 3px 1px rgba(0, 0, 0, 0.12), 0 1px 5px rgba(0, 0, 0, 0.2);
}
.authentication-view__card .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.authentication-view__card .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.authentication-view__card .thumbNail {
  justify-content: center;
}
.authentication-view__card .swiper-slide {
  background-size: cover;
  background-position: center;
}
.authentication-view__card .swiper-slide:hover {
  cursor: pointer;
}
.authentication-view__card .mySwiper {
  margin-top: 20px;
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}
.authentication-view__card .mySwiper .swiper-slide {
  width: 50px !important;
  height: 100%;
  opacity: 0.4;
}
.authentication-view__card .mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}
.authentication-view__card .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.authentication-view__content {
  z-index: 3;
  background-color: #fff;
}
@media screen and (max-width: 767.95px) {
  .authentication-view__content {
    z-index: 2;
  }
}
.authentication-view__content__container {
  display: flex;
}
@media screen and (max-width: 767.95px) {
  .authentication-view__content__container {
    flex-direction: column;
  }
}
.authentication-view__content__card-holder {
  margin-top: -170px;
  background: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  width: 546px;
  height: 600px;
  padding: 30px 0px;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767.95px) {
  .authentication-view__content__card-holder {
    width: 100% !important;
    height: 100% !important;
    display: block;
  }
}
@media screen and (max-width: 1023.95px) {
  .authentication-view__content__card-holder {
    width: 340px;
    height: 450px;
    display: block;
  }
}
.authentication-view__details {
  padding-top: 24px;
  width: 100%;
  margin-left: 50px;
}
@media screen and (max-width: 1279.95px) {
  .authentication-view__details {
    flex-direction: column;
    margin-left: 20px;
  }
}
@media screen and (max-width: 767.95px) {
  .authentication-view__details {
    padding-left: 0;
    margin-left: 0px;
  }
}
@media screen and (max-width: 767.95px) {
  .authentication-view__details-col {
    padding: 0;
    max-width: 100%;
    display: block !important;
  }
}
.authentication-view__details-col:first-child {
  padding-left: 0;
}
.authentication-view__details-col:last-child {
  padding-right: 0;
}
.authentication-view__table {
  width: 100%;
  margin-top: 30px;
}
.authentication-view__table__mobile-content {
  display: none;
}
@media screen and (max-width: 767.95px) {
  .authentication-view__table__mobile-content {
    display: table-row;
  }
}
.authentication-view__table-cell {
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
  padding-left: 24px;
  width: 200px;
}
.authentication-view__table-cell--heading {
  width: auto;
  padding-left: 0;
  font-weight: 500;
}
.authentication-view__heading {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.87);
}
@media screen and (max-width: 767.95px) {
  .authentication-view__heading {
    font-size: 16px;
  }
}
.authentication-view__breakdown {
  padding: 0;
  background: #fff;
}
.authentication-view__breakdown .container {
  display: flex;
}
@media screen and (max-width: 767.95px) {
  .authentication-view__breakdown .container {
    display: block;
  }
}
.authentication-view__breakdown__table {
  width: 100%;
  margin-top: 30px;
}
.authentication-view__breakdown__table-cell {
  padding: 12px 0px;
}
.authentication-view__breakdown__container {
  display: flex;
}
@media screen and (max-width: 767.95px) {
  .authentication-view__breakdown__container {
    flex-direction: column;
  }
}
.authentication-view__breakdown__score, .authentication-view__breakdown__scores {
  width: 100%;
  align-items: center;
  justify-content: center;
}
.authentication-view__breakdown__score--contained, .authentication-view__breakdown__scores--contained {
  background: #6c31bc;
  border-radius: 8px;
}
.authentication-view__breakdown__score--contained,
.authentication-view__breakdown__score--contained .authentication-view__breakdown__label,
.authentication-view__breakdown__score--contained .authentication-view__breakdown__value, .authentication-view__breakdown__scores--contained,
.authentication-view__breakdown__scores--contained .authentication-view__breakdown__label,
.authentication-view__breakdown__scores--contained .authentication-view__breakdown__value {
  color: #fff;
}
.authentication-view__breakdown__score--contained .authentication-view__breakdown__label,
.authentication-view__breakdown__score--contained .authentication-view__breakdown__value, .authentication-view__breakdown__scores--contained .authentication-view__breakdown__label,
.authentication-view__breakdown__scores--contained .authentication-view__breakdown__value {
  text-align: center;
}
.authentication-view__breakdown__score-holder, .authentication-view__breakdown__scores-holder {
  display: inline-flex;
  align-items: stretch;
}
@media screen and (max-width: 767.95px) {
  .authentication-view__breakdown__score-holder, .authentication-view__breakdown__scores-holder {
    padding: 0 !important;
    max-width: 100% !important;
  }
}
.authentication-view__breakdown__score-holder {
  width: 100%;
  max-width: 224px;
}
@media screen and (max-width: 767.95px) {
  .authentication-view__breakdown__score-holder {
    padding-bottom: 24px !important;
  }
}
.authentication-view__breakdown__scores {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.authentication-view__breakdown__scores-holder {
  width: 100%;
  margin-top: 20px;
}
.authentication-view__breakdown__scores-score {
  width: 25%;
  padding: 24px 0;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 767.95px) {
  .authentication-view__breakdown__scores-score {
    width: 50%;
  }
}
.authentication-view__breakdown__scores-score--full {
  width: 100%;
}
.authentication-view__breakdown__label, .authentication-view__breakdown__value {
  width: 100%;
  letter-spacing: 0.2px;
}
.authentication-view__breakdown__label {
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 8px;
}
.authentication-view__breakdown__label--magnify {
  font-size: 16px;
  line-height: 24px;
}
.authentication-view__breakdown__value {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
}
.authentication-view__breakdown__value-label, .authentication-view__breakdown__value--magnify {
  line-height: 48px;
  text-transform: uppercase;
}
.authentication-view__breakdown__value-label {
  font-size: 26px;
}
.authentication-view__breakdown__value--magnify {
  font-size: 48px;
}
.authentication-view__images {
  padding-bottom: 40px;
  background: #fff;
}
.authentication-view__images .container {
  height: 530px;
}
@media only screen and (min-width: 768px) {
  .authentication-view__images .container {
    height: 410px;
  }
}
@media only screen and (min-width: 1280px) {
  .authentication-view__images .container {
    height: 530px;
  }
}
@media screen and (max-width: 767.95px) {
  .authentication-view__images {
    padding-bottom: 0px;
  }
}
.authentication-view__images__image {
  padding: 10px;
  border-radius: 20px;
}
@media screen and (max-width: 767.95px) {
  .authentication-view__images__image {
    padding: 5px 5px 0px 0px;
    border-radius: 8px;
    width: 260px;
    height: 183px;
  }
}
@media only screen and (min-width: 360px) {
  .authentication-view__images__image {
    height: 163px;
  }
}
@media only screen and (min-width: 375px) {
  .authentication-view__images__image {
    height: 170px;
  }
}
@media only screen and (min-width: 390px) {
  .authentication-view__images__image {
    height: 177px;
  }
}
@media only screen and (min-width: 414px) {
  .authentication-view__images__image {
    height: 189px;
  }
}
@media only screen and (min-width: 412px) {
  .authentication-view__images__image {
    height: 183px;
  }
}
@media only screen and (min-width: 540px) {
  .authentication-view__images__image {
    width: 100%;
    height: 100%;
  }
}
.authentication-view__images__caption {
  position: absolute;
  margin-top: -180px;
  transform: rotate(90deg);
  color: white;
  background: rgba(31, 31, 31, 0.49);
  border-radius: 20px;
  padding: 10px 20px;
}
@media screen and (max-width: 1023.95px) {
  .authentication-view__images__caption {
    margin-top: -120px;
  }
}
@media screen and (max-width: 767.95px) {
  .authentication-view__images__caption {
    margin-top: -100px !important;
    margin-left: -20px;
  }
}
@media only screen and (min-width: 1024px) {
  .authentication-view__images__caption {
    margin-top: -136px;
  }
}
@media only screen and (min-width: 658px) {
  .authentication-view__images__caption {
    margin-top: -110px;
    margin-left: 0px;
  }
}
@media only screen and (min-width: 1280px) {
  .authentication-view__images__caption {
    margin-top: -180px;
  }
}
@media only screen and (min-width: 540px) and (max-width: 720px) {
  .authentication-view__images__caption {
    margin-top: -80px;
    margin-left: -20px;
  }
}
@media screen and (max-width: 767.95px) {
  .authentication-view__images__card {
    width: 235px;
  }
}
.authentication-view__slider {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.authentication-view__slider .slick-arrow {
  width: 32px;
  height: 32px;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.7);
}
.authentication-view__slider .slick-arrow:before {
  color: #fff;
}
.authentication-view__slider .slick-arrow.slick-next {
  right: 0;
}
.authentication-view__slider .slick-arrow.slick-next:before {
  content: "chevron_right";
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}
.authentication-view__slider .slick-arrow.slick-prev {
  left: 0;
}
.authentication-view__slider .slick-arrow.slick-prev:before {
  content: "chevron_left";
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}
.authentication-view .ib-image {
  transform: translate(-50%, -50%) rotate(-90deg) !important;
}
.authentication-view__header__share {
  top: 35px;
  right: 20px;
  position: absolute;
  display: none;
}
@media screen and (max-width: 767.95px) {
  .authentication-view__header__share {
    display: block;
  }
}
.authentication-view__header__content {
  display: block;
  min-height: 142px;
  color: #fff;
  width: 100%;
}
.authentication-view__card img {
  max-height: 500px !important;
}
@media screen and (max-width: 767.95px) {
  .authentication-view__card img {
    max-height: 400px !important;
  }
}
@media screen and (max-width: 1023.95px) {
  .authentication-view__card img {
    max-height: 400px !important;
  }
}
.authentication-view__certificate {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #ffffffb2;
  text-transform: uppercase;
  margin-bottom: 10px;
}
@media screen and (max-width: 767.95px) {
  .authentication-view__certificate {
    margin-bottom: 30px;
    font-size: 12px;
    font-weight: 500;
  }
}
.authentication-view__certificate__button {
  text-align: center;
}
.authentication-view__certificate__button a {
  margin-top: 100px;
  margin-bottom: 100px;
}
@media screen and (max-width: 767.95px) {
  .authentication-view__certificate__button a {
    margin-top: 100px;
    margin-bottom: 110px;
    width: 100% !important;
  }
}
.authentication-view__table-cell--heading {
  line-height: 28px !important;
}
.authentication-view__certified-badge {
  background: #20bfb8;
  border-radius: 4px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 72px !important;
  height: 72px !important;
  min-width: 72px !important;
  width: 72px !important;
}
@media screen and (max-width: 767.95px) {
  .authentication-view__certified-badge {
    min-height: 48px !important;
    min-width: 48px !important;
    height: 48px !important;
    width: 48px !important;
    padding-top: 6px !important;
  }
}
@media screen and (max-width: 599.9px) {
  .authentication-view__certified-badge {
    min-height: 48px !important;
    min-width: 48px !important;
    height: 48px !important;
    width: 48px !important;
    padding-top: 6px !important;
  }
}
.authentication-view__subheading {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.200000003px;
  text-align: left;
  padding-right: 10px;
}
.authentication-view__verified-user-icon {
  font-size: 30px;
}
@media screen and (max-width: 767.95px) {
  .authentication-view__verified-user-icon {
    font-size: 35px;
  }
}
.authentication-view__certified-label {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.400000006px;
  text-align: center;
  margin-bottom: 5px;
}
@media screen and (max-width: 767.95px) {
  .authentication-view__certified-label {
    display: none;
  }
}
.authentication-view__description-block {
  display: block !important;
}
.authentication-view__description-block__description {
  color: #0000008a;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.1000000015px;
  text-align: left;
  margin-bottom: 20px;
}
.authentication-view__related-items {
  padding-bottom: 100px;
  background-color: white;
}
@media screen and (max-width: 767.95px) {
  .authentication-view__related-items {
    padding-bottom: 0 !important;
  }
}
.authentication-view__related-items__images-div {
  justify-content: space-between !important;
}
@media screen and (max-width: 767.95px) {
  .authentication-view__related-items__images-div {
    display: flex;
    flex-wrap: nowrap !important;
    overflow-x: auto;
    margin: 20px;
  }
}
.authentication-view__related-items__image-div {
  flex-basis: 23%;
  height: 228px;
}
.authentication-view__related-items__image-div:hover .image {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
}
.authentication-view__related-items__image-div:hover .long-name {
  text-decoration: underline;
}
@media screen and (max-width: 767.95px) {
  .authentication-view__related-items__image-div {
    flex: 0 0 auto;
    width: auto;
    height: 100%;
    max-width: 70% !important;
    margin-right: 20px;
    padding-bottom: 20px;
  }
}
.authentication-view__related-items__image-div .material-icons {
  color: #20bfb8;
  position: absolute;
  right: 5px;
  top: 10px;
}
.authentication-view__related-items__image-div img {
  object-fit: cover;
  width: 135px;
  height: 185px;
  margin: 15px 0;
}
.authentication-view__related-items__image {
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  overflow: hidden;
  position: relative;
  background: #efeff6;
  align-items: center;
  border-radius: 12px;
  flex-direction: column;
}
.authentication-view__related-items__heading {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1000000015px;
  text-align: left;
  margin-bottom: 20px;
}
.authentication-view__related-items__content {
  margin-top: 10px;
  color: black;
}
.authentication-view__related-items__content h1 {
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.200000003px;
  text-align: left;
}
.authentication-view__related-items__content p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1000000015px;
  text-align: left;
}
.authentication-view__related-items__certificate-number {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1000000015px;
  text-align: left;
  color: #0000008a;
}
.authentication-view__certificate-image {
  display: block;
  background-color: white;
  position: relative;
}
.authentication-view__certificate-image__number {
  position: absolute;
  transform: translate(-70%, -31%);
  left: 70%;
  margin-top: 16.75%;
  z-index: 99999;
  font-size: 24px;
}
@media screen and (max-width: 1365.95px) {
  .authentication-view__certificate-image__number {
    font-size: 22px;
    left: 70.5%;
    top: 29%;
    margin-top: 0 !important;
  }
}
@media screen and (max-width: 1023.95px) {
  .authentication-view__certificate-image__number {
    font-size: 22px;
    left: 70.5%;
    top: 29%;
    margin-top: 0 !important;
  }
}
@media screen and (max-width: 767.95px) {
  .authentication-view__certificate-image__number {
    font-size: 12px;
    left: 70.5%;
    top: 29%;
    margin-top: 0 !important;
  }
}
.authentication-view__certificate-image__image-div {
  position: relative;
  display: inline-block;
  margin-top: 70px;
  max-width: 1300px;
  max-height: 671px;
}
@media screen and (max-width: 767.95px) {
  .authentication-view__certificate-image__image-div {
    margin-top: 30px !important;
    margin-bottom: 0 !important;
  }
}
@media screen and (max-width: 767.95px) {
  .authentication-view__table-div-left {
    display: block !important;
  }
}
@media screen and (max-width: 767.95px) {
  .authentication-view__table-div-left .authentication-view__table-cell--heading {
    width: 80px !important;
  }
}
@media screen and (max-width: 767.95px) {
  .authentication-view__table-div-right {
    margin-top: 0 !important;
  }
}
@media screen and (max-width: 767.95px) {
  .authentication-view__table-div-right .authentication-view__table-cell--heading {
    width: 80px !important;
  }
}
@media screen and (max-width: 767.95px) {
  .authentication-view__right-side {
    display: block !important;
  }
}
.authentication-view__button {
  color: #ffffff;
  background: #20bfb8;
  border-radius: 28px;
  padding: 15px 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  min-width: 270px;
  margin-top: 10px;
  margin-bottom: 50px;
  justify-content: start !important;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.14), 0 2px 1px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.2);
  letter-spacing: 0.35px;
  transition: background-color 0.2s ease-in-out;
}
@media screen and (max-width: 767.95px) {
  .authentication-view__button {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100% !important;
  }
}
.authentication-view__button:hover {
  background-color: #35ddd6 !important;
}

.feed-hero {
  background-image: linear-gradient(to bottom, rgba(3, 3, 66, 0.5), rgba(0, 0, 190, 0.5)), url("/assets/images/feedpage-background.png");
  background-repeat: round;
}
.feed-hero__container {
  width: 100%;
  padding: 10px;
}
.feed-hero__search-box {
  margin: auto;
  width: 50%;
}
@media screen and (max-width: 959.95px) {
  .feed-hero__search-box {
    width: 100%;
  }
}
.feed-hero__input {
  width: 630px;
  height: 48px;
  left: 400px;
  top: 234px;
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 24px;
  padding: 20px 37px;
}
@media screen and (max-width: 959.95px) {
  .feed-hero__input {
    width: 100%;
  }
}
.feed-hero__clear-button {
  position: absolute;
  margin-left: -30px;
  margin-top: 12px;
  color: rgba(0, 0, 0, 0.54);
}
@media screen and (max-width: 959.95px) {
  .feed-hero__clear-button {
    position: absolute;
  }
}
.feed-hero__search-icon {
  position: absolute;
  margin-left: 10px;
  margin-top: 12px;
  width: 10px;
  color: rgba(0, 0, 0, 0.54);
}
@media screen and (max-width: 959.95px) {
  .feed-hero__search-icon {
    position: absolute;
  }
}
.feed-hero__text {
  padding: 24px 0;
}
@media screen and (max-width: 599.95px) {
  .feed-hero__text {
    @apply items-center w-full;
    padding-top: 12px;
  }
}
.feed-hero__text-heading, .feed-hero__text-subheading {
  color: white;
}
.feed-hero__text-heading {
  text-align: center;
  color: white;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: 0.2px;
  margin-bottom: 4px;
}
@media screen and (max-width: 959.95px) {
  .feed-hero__text-heading {
    font-size: 32px;
  }
}
.feed-hero__text-subheading {
  text-align: center;
  font-size: 16px;
}
@media screen and (max-width: 959.95px) {
  .feed-hero__text-subheading {
    font-size: 14px;
  }
}
.feed-hero__splash {
  @apply w-4/12 flex justify-end items-center;
}
@media screen and (max-width: 599.95px) {
  .feed-hero__splash {
    display: none;
  }
}
.feed-list {
  padding: 20px 0;
  flex: 1 1 auto;
  color: #000000de;
  background-color: #ffffff;
}
@media screen and (max-width: 767.95px) {
  .feed-list {
    padding-top: 24px;
    padding-bottom: 0;
    display: block !important;
  }
}
.feed-list__table {
  @apply w-full table-auto;
  box-sizing: border-box;
}
@media screen and (max-width: 1279.95px) {
  .feed-list__table__container {
    padding-left: 0;
    padding-right: 0;
  }
}
.feed-list__table-holder {
  max-width: 100%;
  overflow-x: auto;
}
.feed-list__table-row {
  border-bottom: 1px solid #e0e0e0;
}
.feed-list__table-cell {
  padding: 16px;
  text-align: left;
}
.feed-list__table-cell--date, .feed-list__table-cell--certificate, .feed-list__table-cell--owner {
  width: 140px;
}
@media screen and (max-width: 959.95px) {
  .feed-list__table-cell--date, .feed-list__table-cell--certificate, .feed-list__table-cell--owner {
    @apply hidden;
  }
}
.feed-list__table-cell--grade {
  width: 96px;
  text-align: right;
}
.feed-list__table-head .feed-list__table-cell {
  padding-top: 20px;
  padding-bottom: 20px;
}
@media screen and (max-width: 959.95px) {
  .feed-list__table-head .feed-list__table-cell {
    @apply hidden;
  }
}
.feed-list__table__info {
  @apply flex items-center justify-start;
}
.feed-list__table__info-image {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
}
.feed-list__table__info-text {
  padding-left: 14px;
  max-width: 240px;
}
.feed-list__table__info-heading {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.feed-list__table__info-subheading {
  font-size: 12px;
}
.feed-list__table__info-date {
  @apply hidden;
}
@media screen and (max-width: 959.95px) {
  .feed-list__table__info-date {
    @apply block pt-2 text-xs;
  }
}
.feed-list__grade {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  background-color: #42f9ff;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
}
.feed-list__grade__label {
  margin-bottom: 6px;
  font-size: 12px;
  line-height: 12px;
  color: rgba(0, 0, 0, 0.87);
}
.feed-list__grade__value {
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
}

.feed-view__warning {
  color: white;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  font-size: 16px;
  font-weight: bold;
}
.feed-view__table-div-left {
  width: 320px;
  display: table-row;
}
@media screen and (max-width: 767.95px) {
  .feed-view__table-div-left {
    display: none;
  }
}
@media screen and (max-width: 1023.95px) {
  .feed-view__table-div-left {
    width: 100%;
    height: 100%;
  }
}
.feed-view__table-div-right {
  width: 320px;
}
@media screen and (max-width: 1023.95px) {
  .feed-view__table-div-right {
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 767.95px) {
  .feed-view__conclusion {
    background: #f4f4fb;
    padding-top: 40px;
    margin-top: 50px;
  }
}
.feed-view__conclusion__button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0px;
}
.feed-view__conclusion__button .MuiButton-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.35px;
  color: #ffffff;
  background: #20bfb8;
  border-radius: 28px;
  padding: 15px 20px;
}
.feed-view__conclusion__text {
  padding: 60px;
}
@media screen and (max-width: 767.95px) {
  .feed-view__conclusion__text {
    padding: 0px;
  }
}
.feed-view__conclusion__text__heading {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 20px;
}
.feed-view__conclusion__text__subHeading {
  font-weight: 900;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}
@media screen and (max-width: 767.95px) {
  .feed-view__conclusion__text__subHeading {
    font-size: 24px;
    line-height: 38px;
  }
}
.feed-view__search-card {
  position: relative;
  background-color: #5b35ab;
}
.feed-view__search-card .container {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 767.95px) {
  .feed-view__search-card {
    background-color: #5b35ab;
    padding-top: 100px;
    padding-bottom: 60px;
  }
}
.feed-view__search-card__text {
  padding: 110px;
  display: block;
}
@media screen and (max-width: 767.95px) {
  .feed-view__search-card__text {
    padding: 0px;
  }
}
.feed-view__search-card__text__subHeading {
  font-weight: 900;
  font-size: 32px;
  line-height: 47px;
  text-align: center;
  color: white;
}
@media screen and (max-width: 767.95px) {
  .feed-view__search-card__text__subHeading {
    margin-bottom: 20px;
    font-size: 28px;
    line-height: 38px;
  }
}
.feed-view__searchCardsBackgroundLeft {
  height: 96%;
  width: 36.5%;
  top: 10px;
}
@media screen and (max-width: 767.95px) {
  .feed-view__searchCardsBackgroundLeft {
    margin-left: -10px;
  }
}
.feed-view__searchCardsBackgroundRight {
  right: 0;
  height: 96%;
  width: 36.5%;
  top: 10px;
}
@media screen and (max-width: 767.95px) {
  .feed-view__searchCardsBackgroundRight {
    margin-right: -10px;
  }
}
.feed-view__searchCardsBackgroundLeft, .feed-view__searchCardsBackgroundRight {
  position: absolute;
}
@media screen and (max-width: 767.95px) {
  .feed-view__searchCardsBackgroundLeft, .feed-view__searchCardsBackgroundRight {
    height: 40%;
    width: 50%;
    bottom: 0;
    top: unset;
    margin-top: 10px;
  }
}
.feed-view__graph {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding-bottom: 60px;
}
.feed-view__graph .apexcharts-bar-area:hover {
  fill: #20bfb8;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 1919.95px) {
  .feed-view__graph__container {
    overflow: scroll;
  }
}
.feed-view__graph__content .apexcharts-title-text {
  padding: 20px !important;
}
.feed-view__graph__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f4f4fb;
  padding: 20px;
}
@media screen and (max-width: 767.95px) {
  .feed-view__graph__header {
    width: 100%;
  }
}
.feed-view__graph__title {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
}
.feed-view__graph__subTitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #ffffff;
  background: #20bfb8;
  border-radius: 4px;
  padding: 10px 20px;
}
.feed-view__share {
  background: #f4f4fb;
  border-radius: 8px;
  width: 320px;
  height: 80px;
  padding: 15px;
}
@media screen and (max-width: 1023.95px) {
  .feed-view__share {
    width: 100%;
    height: 100%;
  }
}
.feed-view__share__title {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.54);
}
.feed-view__share__icon {
  color: rgba(0, 0, 0, 0.54);
  padding-top: 10px;
  padding-bottom: 5px;
}
.feed-view__share__icon:hover {
  cursor: pointer;
}
.feed-view__grading-standard {
  display: flex;
  margin: 10px 0px;
}
.feed-view__grading-standard:hover {
  cursor: pointer;
}
.feed-view__grading-standard__icon-div {
  width: 30px;
}
.feed-view__grading-standard__text {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: #42e8e0;
  margin-top: 2px;
}
.feed-view__grading-standard .material-icons {
  font-weight: 500;
  color: #42e8e0;
}
.feed-view__ags-population {
  background: #f4f4fb;
  border-radius: 8px;
  width: 320px;
  height: 80px;
  display: flex;
  justify-content: space-between;
  padding: 15px;
}
@media screen and (max-width: 1023.95px) {
  .feed-view__ags-population {
    width: 100%;
    height: 100%;
    padding: 10px;
  }
}
@media screen and (max-width: 767.95px) {
  .feed-view__ags-population {
    width: 100%;
    padding: 15px !important;
  }
}
.feed-view__ags-population__count {
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #251f33;
  padding-top: 10px;
}
.feed-view__ags-population__title {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.54);
}
.feed-view__ags-population__icon:hover {
  cursor: pointer;
}
.feed-view__ags-population__icon {
  display: flex;
}
.feed-view__ags-population__icon__text {
  margin-top: 2px;
  margin-left: 2px;
  color: #20bfb8;
}
.feed-view__ags-population__icon .material-icons {
  color: #20bfb8;
}
.feed-view__right-side {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767.95px) {
  .feed-view__right-side {
    margin-left: 0px;
  }
}
.feed-view__left-side {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767.95px) {
  .feed-view__share {
    display: none;
  }
}
.feed-view__generated-images {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
.feed-view__generated-images__actions {
  display: flex;
  justify-content: center;
  align-items: center;
}
.feed-view__generated-images__text {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
  padding: 20px;
}
.feed-view__generated-images__front {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 40px 0px;
}
.feed-view__generated-images__front__image {
  width: 403px;
  height: auto;
  background: #000;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.14)) drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.12)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2));
  border-radius: 8px;
  transform: rotate(90deg);
  margin: 0px;
}
.feed-view__generated-images__back {
  display: none;
}
.feed-view__generated-images__back__image {
  width: 20%;
}
.feed-view__generated-images__buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.feed-view__generated-images__buttons__front {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #20bfb8;
  background: rgba(32, 191, 184, 0.08);
  border: 1px solid #20bfb8;
  border-radius: 24px;
  padding: 10px 20px;
  margin: 5px;
}
.feed-view__generated-images__buttons__back {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: rgba(0, 0, 0, 0.54);
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.18);
  border-radius: 24px;
  padding: 10px 20px;
  margin: 5px;
}
.feed-view__header {
  position: relative;
  overflow: hidden;
  padding-top: 86px;
}
@media screen and (max-width: 767.95px) {
  .feed-view__header {
    padding-top: 0;
  }
}
.feed-view__header__overlay {
  z-index: 1;
}
.feed-view__header__overlay, .feed-view__header__overlay-background, .feed-view__header__overlay-color {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: black;
  overflow: hidden;
  background: linear-gradient(rgba(37, 31, 51, 0.7), rgba(37, 31, 51, 0.7));
}
.feed-view__header__overlay-background {
  z-index: 1;
  object-fit: cover;
  object-position: center center;
}
.feed-view__header__overlay-color {
  z-index: 3;
}
.feed-view__header__container {
  position: relative;
  z-index: 1;
  display: flex;
}
.feed-view__header__content {
  min-height: 142px;
  color: #fff;
  display: flex;
  align-items: center;
  width: 100%;
}
@media screen and (max-width: 767.95px) {
  .feed-view__header__content {
    max-width: 100%;
    margin-bottom: 50%;
    margin-top: 20px;
  }
}
@media screen and (max-width: 599.95px) {
  .feed-view__header__content {
    padding-left: 0;
    padding-bottom: 14px;
    padding-top: 14px;
  }
}
.feed-view__header__details {
  width: 100%;
  flex: 1 1 auto;
  padding-right: 14px;
}
.feed-view__header__certificate {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  margin-bottom: 12px;
}
@media screen and (max-width: 767.95px) {
  .feed-view__header__certificate {
    margin-bottom: 30px;
  }
}
.feed-view__header__share {
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 35px;
  display: none;
}
@media screen and (max-width: 599.95px) {
  .feed-view__header__share {
    display: flex;
  }
}
.feed-view__header__heading, .feed-view__header__subheading {
  color: #fff;
}
.feed-view__header__heading {
  font-size: 34px;
  line-height: 40px;
  margin-bottom: 6px;
  font-weight: 700;
}
@media screen and (max-width: 599.95px) {
  .feed-view__header__heading {
    font-size: 24px !important;
  }
}
@media screen and (max-width: 1023.95px) {
  .feed-view__header__heading {
    font-size: 30px;
  }
}
.feed-view__header__subheading {
  font-size: 16px;
  line-height: 24px;
}
@media screen and (max-width: 599.95px) {
  .feed-view__header__subheading {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 1023.95px) {
  .feed-view__header__subheading {
    font-size: 14px;
  }
}
.feed-view__header__score {
  display: inline-flex;
  justify-content: flex-end;
}
.feed-view__header__grade {
  width: 100px;
  height: 90px;
  background: #20bfb8;
  border-radius: 8px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 599.95px) {
  .feed-view__header__grade {
    padding: 10px 14px;
    min-width: 71px;
    min-height: 82px;
  }
}
.feed-view__header__grade-label, .feed-view__header__grade-score {
  text-align: center;
  color: rgba(255, 255, 255, 0.87);
}
.feed-view__header__grade-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #ffffff;
  margin-bottom: 6px;
  text-transform: uppercase;
}
@media screen and (max-width: 599.95px) {
  .feed-view__header__grade-label {
    font-size: 12px;
    margin-bottom: 0;
  }
}
.feed-view__header__grade-score {
  font-weight: 900;
  font-size: 44px;
  line-height: 52px;
  text-align: center;
  color: #ffffff;
}
@media screen and (max-width: 599.95px) {
  .feed-view__header__grade-score {
    font-size: 40px;
  }
}
@media screen and (max-width: 767.95px) {
  .feed-view__header .feed-view__card-holder {
    display: none;
  }
}
.feed-view__card .container {
  width: 546px;
}
.feed-view__card-holder {
  width: 92%;
}
@media screen and (max-width: 767.95px) {
  .feed-view__card-holder {
    width: 100%;
  }
}
.feed-view__card-image {
  width: 50%;
  border-radius: 4%;
  margin: auto;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.14), 0 3px 1px rgba(0, 0, 0, 0.12), 0 1px 5px rgba(0, 0, 0, 0.2);
}
.feed-view__card .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.feed-view__card .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.feed-view__card .thumbNail {
  justify-content: center;
}
.feed-view__card .swiper-slide {
  background-size: cover;
  background-position: center;
}
.feed-view__card .swiper-slide:hover {
  cursor: pointer;
}
.feed-view__card .mySwiper {
  margin-top: 20px;
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}
.feed-view__card .mySwiper .swiper-slide {
  width: 50px !important;
  height: 100%;
  opacity: 0.4;
}
.feed-view__card .mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}
.feed-view__card .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.feed-view__content {
  z-index: 3;
  background-color: #fff;
}
@media screen and (max-width: 767.95px) {
  .feed-view__content {
    z-index: 2;
  }
}
.feed-view__content__container {
  display: flex;
}
@media screen and (max-width: 767.95px) {
  .feed-view__content__container {
    flex-direction: column;
  }
}
.feed-view__content__card-holder {
  margin-top: -170px;
  background: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  width: 546px;
  height: 600px;
  padding: 30px 0px;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767.95px) {
  .feed-view__content__card-holder {
    width: 100% !important;
    height: 100% !important;
    display: block;
  }
}
@media screen and (max-width: 1023.95px) {
  .feed-view__content__card-holder {
    width: 340px;
    height: 450px;
    display: block;
  }
}
.feed-view__details {
  padding-top: 24px;
  width: 100%;
  margin-left: 50px;
}
@media screen and (max-width: 1279.95px) {
  .feed-view__details {
    flex-direction: column;
    margin-left: 20px;
  }
}
@media screen and (max-width: 767.95px) {
  .feed-view__details {
    padding-left: 0;
    margin-left: 0px;
  }
}
@media screen and (max-width: 767.95px) {
  .feed-view__details-col {
    padding: 0;
    max-width: 100%;
    display: block !important;
  }
}
.feed-view__details-col:first-child {
  padding-left: 0;
}
.feed-view__details-col:last-child {
  padding-right: 0;
}
.feed-view__table {
  width: 100%;
  margin-top: 30px;
}
.feed-view__table__mobile-content {
  display: none;
}
@media screen and (max-width: 767.95px) {
  .feed-view__table__mobile-content {
    display: table-row;
  }
}
.feed-view__table-cell {
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
  padding-left: 24px;
  width: 200px;
}
.feed-view__table-cell--heading {
  width: auto;
  padding-left: 0;
  font-weight: 500;
}
.feed-view__table__request-details {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.35px;
  color: #20bfb8;
  transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.feed-view__table__request-details:hover {
  color: #42f9ff;
}
.feed-view__breakdown__top-section, .feed-view__images__top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 10px;
}
@media screen and (max-width: 767.95px) {
  .feed-view__breakdown__top-section, .feed-view__images__top-section {
    margin-left: 0px;
  }
}
.feed-view__breakdown__heading, .feed-view__images__heading {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.87);
}
@media screen and (max-width: 767.95px) {
  .feed-view__breakdown__heading, .feed-view__images__heading {
    font-size: 16px;
  }
}
.feed-view__laser-heightmap {
  background: #fff;
}
@media screen and (max-width: 767.95px) {
  .feed-view__laser-heightmap {
    padding-top: 60px;
  }
}
.feed-view__laser-heightmap__heading {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.87);
}
@media screen and (max-width: 767.95px) {
  .feed-view__laser-heightmap__heading {
    font-size: 16px;
  }
}
.feed-view__breakdown {
  padding: 0;
  background: #fff;
}
.feed-view__breakdown .container {
  display: flex;
}
@media screen and (max-width: 767.95px) {
  .feed-view__breakdown .container {
    display: block;
  }
}
.feed-view__breakdown--overall, .feed-view__breakdown--front, .feed-view__breakdown--back {
  padding-top: 40px;
  padding-bottom: 60px;
}
@media screen and (max-width: 1023.95px) {
  .feed-view__breakdown--overall, .feed-view__breakdown--front, .feed-view__breakdown--back {
    padding-bottom: 40px;
  }
}
.feed-view__breakdown__front {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  width: 100%;
  padding: 20px;
}
.feed-view__breakdown__back {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  width: 100%;
  padding: 20px;
  margin-left: 12px;
}
@media screen and (max-width: 767.95px) {
  .feed-view__breakdown__back {
    margin-left: 0px;
    margin-top: 30px;
  }
}
.feed-view__breakdown__table {
  width: 100%;
  margin-top: 30px;
}
.feed-view__breakdown__table-cell {
  padding: 12px 0px;
}
.feed-view__breakdown--overall {
  padding-top: 40px;
  padding-bottom: 0;
}
@media screen and (max-width: 767.95px) {
  .feed-view__breakdown--overall {
    padding-top: 24px;
  }
}
.feed-view__breakdown__container {
  display: flex;
}
@media screen and (max-width: 767.95px) {
  .feed-view__breakdown__container {
    flex-direction: column;
  }
}
.feed-view__breakdown__score, .feed-view__breakdown__scores {
  width: 100%;
  align-items: center;
  justify-content: center;
}
.feed-view__breakdown__score--card-price, .feed-view__breakdown__scores--card-price {
  margin-top: 10px;
  align-items: center;
  justify-content: left;
}
.feed-view__breakdown__score--contained, .feed-view__breakdown__scores--contained {
  background: #6c31bc;
  border-radius: 8px;
}
.feed-view__breakdown__score--contained,
.feed-view__breakdown__score--contained .feed-view__breakdown__label,
.feed-view__breakdown__score--contained .feed-view__breakdown__value, .feed-view__breakdown__scores--contained,
.feed-view__breakdown__scores--contained .feed-view__breakdown__label,
.feed-view__breakdown__scores--contained .feed-view__breakdown__value {
  color: #fff;
}
.feed-view__breakdown__score--contained .feed-view__breakdown__label,
.feed-view__breakdown__score--contained .feed-view__breakdown__value, .feed-view__breakdown__scores--contained .feed-view__breakdown__label,
.feed-view__breakdown__scores--contained .feed-view__breakdown__value {
  text-align: center;
}
.feed-view__breakdown__score-holder, .feed-view__breakdown__scores-holder {
  display: inline-flex;
  align-items: stretch;
}
@media screen and (max-width: 767.95px) {
  .feed-view__breakdown__score-holder, .feed-view__breakdown__scores-holder {
    padding: 0 !important;
    max-width: 100% !important;
  }
}
.feed-view__breakdown__score-holder {
  width: 100%;
  max-width: 224px;
}
@media screen and (max-width: 767.95px) {
  .feed-view__breakdown__score-holder {
    padding-bottom: 24px !important;
  }
}
.feed-view__breakdown__scores {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.feed-view__breakdown__scores-holder {
  width: 100%;
  margin-top: 30px;
}
.feed-view__breakdown__scores-card-price {
  margin-top: 10px;
}
.feed-view__breakdown__scores-score {
  width: 25%;
  padding: 24px 0;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 767.95px) {
  .feed-view__breakdown__scores-score {
    width: 50%;
  }
}
.feed-view__breakdown__scores-score--full {
  width: 100%;
}
.feed-view__breakdown__label, .feed-view__breakdown__value {
  width: 100%;
  letter-spacing: 0.2px;
}
.feed-view__breakdown__label {
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 8px;
}
.feed-view__breakdown__label--magnify {
  font-size: 16px;
  line-height: 24px;
}
.feed-view__breakdown__value {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
}
.feed-view__breakdown__value-label, .feed-view__breakdown__value--magnify {
  line-height: 48px;
  text-transform: uppercase;
}
.feed-view__breakdown__value-label {
  font-size: 26px;
}
.feed-view__breakdown__value--magnify {
  font-size: 48px;
}
.feed-view__images {
  padding-bottom: 40px;
  background: #fff;
}
.feed-view__images .container {
  height: 530px;
}
@media only screen and (min-width: 768px) {
  .feed-view__images .container {
    height: 410px;
  }
}
@media only screen and (min-width: 1280px) {
  .feed-view__images .container {
    height: 530px;
  }
}
@media screen and (max-width: 767.95px) {
  .feed-view__images {
    padding-bottom: 0px;
  }
}
.feed-view__images__image {
  padding: 10px;
  border-radius: 20px;
}
@media screen and (max-width: 767.95px) {
  .feed-view__images__image {
    padding: 5px 5px 0px 0px;
    border-radius: 8px;
    width: 260px;
    height: 183px;
  }
}
@media only screen and (min-width: 360px) {
  .feed-view__images__image {
    height: 163px;
  }
}
@media only screen and (min-width: 375px) {
  .feed-view__images__image {
    height: 170px;
  }
}
@media only screen and (min-width: 390px) {
  .feed-view__images__image {
    height: 177px;
  }
}
@media only screen and (min-width: 414px) {
  .feed-view__images__image {
    height: 189px;
  }
}
@media only screen and (min-width: 412px) {
  .feed-view__images__image {
    height: 183px;
  }
}
@media only screen and (min-width: 540px) {
  .feed-view__images__image {
    width: 100%;
    height: 100%;
  }
}
.feed-view__images__caption {
  position: absolute;
  margin-top: -180px;
  transform: rotate(90deg);
  color: white;
  background: rgba(31, 31, 31, 0.49);
  border-radius: 20px;
  padding: 10px 20px;
}
@media screen and (max-width: 1023.95px) {
  .feed-view__images__caption {
    margin-top: -120px;
  }
}
@media screen and (max-width: 767.95px) {
  .feed-view__images__caption {
    margin-top: -100px !important;
    margin-left: -20px;
  }
}
@media only screen and (min-width: 1024px) {
  .feed-view__images__caption {
    margin-top: -136px;
  }
}
@media only screen and (min-width: 658px) {
  .feed-view__images__caption {
    margin-top: -110px;
    margin-left: 0px;
  }
}
@media only screen and (min-width: 1280px) {
  .feed-view__images__caption {
    margin-top: -180px;
  }
}
@media only screen and (min-width: 540px) and (max-width: 720px) {
  .feed-view__images__caption {
    margin-top: -80px;
    margin-left: -20px;
  }
}
@media screen and (max-width: 767.95px) {
  .feed-view__images__card {
    width: 235px;
  }
}
.feed-view__images__cards-front {
  display: flex;
  flex-wrap: wrap;
  width: 433px;
  height: 100%;
  transform: translateX(-100%) rotate(-90deg);
  transform-origin: right top;
}
@media screen and (max-width: 767.95px) {
  .feed-view__images__cards-front {
    width: 470px;
    height: 66%;
  }
}
@media only screen and (min-width: 360px) {
  .feed-view__images__cards-front {
    width: 470px;
    height: 60%;
  }
}
@media only screen and (min-width: 412px) {
  .feed-view__images__cards-front {
    width: 470px;
    height: 70%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .feed-view__images__cards-front {
    width: 322px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .feed-view__images__cards-front {
    width: 254px;
  }
}
@media only screen and (min-width: 540px) and (max-width: 720px) {
  .feed-view__images__cards-front {
    width: 175px;
  }
}
@media only screen and (min-width: 820px) and (max-width: 1180px) {
  .feed-view__images__cards-front {
    width: 270px;
  }
}
@media only screen and (min-width: 912px) and (max-width: 1368px) {
  .feed-view__images__cards-front {
    width: 305px;
  }
}
@media only screen and (min-width: 1024px) {
  .feed-view__images__cards-front {
    width: 325px;
  }
}
@media only screen and (min-width: 1280px) {
  .feed-view__images__cards-front {
    width: 433px;
  }
}
.feed-view__images__cards-back {
  display: none;
  width: 433px;
  height: 100%;
  flex-wrap: wrap;
  transform: translateX(-100%) rotate(-90deg);
  transform-origin: right top;
}
@media screen and (max-width: 767.95px) {
  .feed-view__images__cards-back {
    width: 470px;
    height: 66%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .feed-view__images__cards-back {
    width: 322px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .feed-view__images__cards-back {
    width: 254px;
  }
}
@media only screen and (min-width: 540px) and (max-width: 720px) {
  .feed-view__images__cards-back {
    width: 175px;
  }
}
@media only screen and (min-width: 820px) and (max-width: 1180px) {
  .feed-view__images__cards-back {
    width: 270px;
  }
}
@media only screen and (min-width: 912px) and (max-width: 1368px) {
  .feed-view__images__cards-back {
    width: 305px;
  }
}
@media only screen and (min-width: 1024px) {
  .feed-view__images__cards-back {
    width: 325px;
  }
}
@media only screen and (min-width: 1280px) {
  .feed-view__images__cards-back {
    width: 433px;
  }
}
.feed-view__slider {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.feed-view__slider .slick-arrow {
  width: 32px;
  height: 32px;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.7);
}
.feed-view__slider .slick-arrow:before {
  color: #fff;
}
.feed-view__slider .slick-arrow.slick-next {
  right: 0;
}
.feed-view__slider .slick-arrow.slick-next:before {
  content: "chevron_right";
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}
.feed-view__slider .slick-arrow.slick-prev {
  left: 0;
}
.feed-view__slider .slick-arrow.slick-prev:before {
  content: "chevron_left";
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.SubmissionButton {
  padding: 14px 24px;
  background-color: #20bfb8;
  border-radius: 28px;
  display: inline-block;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.14), 0 2px 1px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.2);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.35px;
  color: #fff;
  text-transform: uppercase;
  transition: background-color 0.2s ease-in-out;
  text-align: center;
}
.SubmissionButton:hover {
  background-color: #35ddd6;
}

.feed-pagination-section {
  display: flex;
  justify-content: flex-end;
  margin: 20px 20px;
}
.feed-pagination-section__pagination {
  margin-top: -22px;
  width: 100px;
}

.ib-image {
  transform: translate(-50%, -50%) rotate(-90deg) !important;
}

@media screen and (max-width: 767.95px) {
  .feed-grid {
    display: none !important;
  }
}
.feed-grid__top-section {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.feed-grid__text {
  padding: 10px 10px;
}
.feed-grid__grade-nickname {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #ffffff;
}
.feed-grid__overall-grade {
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}
.feed-grid__grade {
  padding: 5px 5px;
  margin: 10px 10px;
  width: 25%;
  height: 60px;
  background: #20bfb8;
  border-radius: 4px;
}
.feed-grid__card-name {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: rgba(0, 0, 0, 0.87);
}
.feed-grid__card-long-name {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: rgba(0, 0, 0, 0.87);
}
.feed-grid__grid-view {
  width: 300px;
  height: 411px;
  left: 80px;
  top: 442px;
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  flex: 1 1 auto;
  display: inline-flex;
  flex-direction: column;
  margin: 10px 4px;
  align-items: center;
}
.feed-grid__image-section {
  padding: 10px 10px;
}
.feed-grid__image {
  width: 170.66px;
  height: 237.78px;
  left: 147.17px;
  top: 542.35px;
}
.feed-grid__bottom-section {
  width: 100%;
  padding: 10px 10px;
}
.feed-grid__graded-date, .feed-grid__certificate, .feed-grid__owner-name {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: rgba(0, 0, 0, 0.54);
}

.feed-pagintion-section__pagination {
  margin: 0px 30px;
  display: inline-flex;
}
@media screen and (max-width: 767.95px) {
  .feed-pagintion-section__pagination {
    margin: 0px 0px;
  }
}
@media screen and (max-width: 767.95px) {
  .feed-pagintion-section__navigation-pages {
    margin-left: 15px;
  }
}
.feed-pagintion-section__navigation-button {
  margin: 0px 20px;
}
@media screen and (max-width: 767.95px) {
  .feed-pagintion-section__navigation-button {
    margin: 0px 5px;
  }
}

.accordion-button {
  color: black !important;
}

.feed-sort__dropdown {
  width: 146px;
  margin-top: 4px !important;
  margin-left: -25px !important;
  background: #ffffff;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: auto;
}

.feed-sort__dropdown-items {
  padding: 10px 10px;
}

.feed-sort__dropdown-items:hover {
  background-color: #e3f0f6;
}

.feed-categories__container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.feed-categories__refinement-list {
  margin: 20px 0px;
}
@media screen and (max-width: 767.95px) {
  .feed-categories__refinement-list {
    display: none;
  }
}
.feed-categories__sort-by-container {
  margin-top: -10px;
  margin-left: 10px;
  height: 50px;
  border-left: 1px solid #e0e0e0;
}
.feed-categories__sort-by-text {
  margin-left: 10px;
}
.feed-categories__category-list {
  width: 200px;
  height: 40px;
  margin-left: 10px;
  padding: 10px 35px;
  display: inline;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.18);
  box-sizing: border-box;
  border-radius: 24px;
}
.feed-categories__clear-refinements-selected {
  margin-top: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #20bfb8;
  width: 160px;
  height: 40px;
  margin-left: 10px;
  padding: 10px 10px;
  display: inline;
  background: #e3f0f6;
  border: 1px solid #20bfb8;
  box-sizing: border-box;
  border-radius: 24px;
}
.feed-categories__clear-refinements-not-selected {
  margin-top: 20px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: rgba(0, 0, 0, 0.54);
  width: 160px;
  height: 40px;
  margin-left: 10px;
  padding: 10px 10px;
  display: inline;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.18);
  box-sizing: border-box;
  border-radius: 24px;
}
.feed-categories__icon {
  position: absolute;
  margin-left: -26px;
  top: 230px;
}
.feed-categories__clear-refinements-icon {
  position: absolute;
  left: 143px;
  width: 10px;
  top: 229px;
  color: #20bfb8;
}
@media screen and (max-width: 767.95px) {
  .feed-categories__clear-refinements {
    display: none;
  }
}
.feed-categories__category-name-not-selected {
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: rgba(0, 0, 0, 0.54);
}
.feed-categories__dropdown {
  width: 146px;
  height: 257px;
  margin-top: 4px !important;
  margin-left: 9px !important;
  background: #ffffff;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: auto;
}
.feed-categories__category-name-selected {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #20bfb8;
}
.feed-categories__filters {
  display: flex;
  align-items: stretch;
}
.feed-categories__menu-select {
  border-left: 1px solid rgba(0, 0, 0, 0.18);
  margin: 22px 10px;
}
@media screen and (max-width: 767.95px) {
  .feed-categories__menu-select {
    display: none;
  }
}
.feed-categories__dropdown-icon {
  position: absolute;
  margin-left: 10px;
  margin-top: -1px;
}
.feed-categories__grade {
  margin-left: 10px;
  padding: 10px 20px;
  width: 130px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.18);
  box-sizing: border-box;
  border-radius: 24px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.2px;
  color: rgba(0, 0, 0, 0.54);
}
.feed-categories__grade-selected {
  background: #e3f0f6;
  border: 1px solid #20bfb8;
  box-sizing: border-box;
  border-radius: 24px;
  margin-left: 10px;
  padding: 10px 20px;
  width: 140px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.2px;
  color: #20bfb8;
}
.feed-categories__close-icon {
  position: absolute;
  margin-left: 10px;
  margin-top: -1px;
  color: #ffffff;
  background: #20bfb8;
  border-radius: 10px;
}
.feed-categories__grade:focus {
  background-color: #e3f0f6;
  color: #20bfb8;
}
.feed-categories__grade-option {
  background: #ffffff;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
.feed-categories__dropdown-items {
  padding: 5px 5px;
  height: 40px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87);
}
.feed-categories__dropdown-items:hover {
  background-color: #e3f0f6;
}
.feed-categories__views {
  display: flex;
  margin-top: 32px;
}
@media screen and (max-width: 767.95px) {
  .feed-categories__views {
    display: none;
  }
}
.feed-categories__list-view-button {
  margin-right: 10px;
}

.feed-stats__stats-button {
  position: absolute;
  width: 382px;
  height: 48px;
  top: 600px;
  background: #20bfb8;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 28px;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.35px;
  text-transform: uppercase;
  color: #ffffff;
}
.feed-stats__text-mobile {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  border-bottom: 1px solid #e0e0e0;
}
.feed-stats__current-refinements-list {
  margin-left: 10px;
  height: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #20bfb8;
  background: #edfaf9;
  border: 1px solid #20bfb8;
  box-sizing: border-box;
  border-radius: 24px;
  padding: 10px 15px;
  display: none;
}
@media screen and (max-width: 767.95px) {
  .feed-stats__current-refinements-list {
    display: inline-block;
  }
}
.feed-stats__current-refinements-button {
  background: #20bfb8;
  border-radius: 20px;
  padding: 2px 8px;
  color: white;
  margin-left: 10px;
}
.feed-stats__heading {
  padding: 20px 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.87);
}
.feed-stats__close-button {
  width: 20%;
  color: rgba(0, 0, 0, 0.54);
  font-size: 30px;
}
.feed-stats__container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
@media screen and (max-width: 767.95px) {
  .feed-stats__container {
    padding: 20px 20px;
  }
}
.feed-stats__sort-and-filter {
  border: 1px solid rgba(0, 0, 0, 0.18);
  box-sizing: border-box;
  border-radius: 24px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: rgba(0, 0, 0, 0.54);
  display: none;
}
@media screen and (max-width: 767.95px) {
  .feed-stats__sort-and-filter {
    width: 50%;
    display: block;
  }
}
.feed-stats__count {
  background: #9a9a9a;
  color: #ffffff;
  border-radius: 20px;
  padding: 5px 10px;
  margin-left: -10px;
}
.feed-stats__icon {
  margin-left: -30px;
  margin-top: -3px;
  position: absolute;
}
.feed-stats__text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 40px;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.87);
}
.feed-stats__total {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.54);
}
.feed-stats__sort-and-filter-mobile {
  height: 100%;
  width: 0%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: white;
  overflow-x: hidden;
}

.page--feed-list {
  background-color: #ffffff;
}

.referral-hero__container {
  background-image: url("/assets/images/partner-banner.png");
  height: 790px;
  overflow: hidden;
  text-align: center;
  color: #ffffff;
  background-size: cover;
  background-position: center;
}

@media screen and (max-width: 767px) {
  .referral-hero__container {
    height: 575px;
  }
}
.referral-hero__overlay {
  display: flex;
  background-color: rgba(34, 28, 46, 0.65);
  height: 790px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .referral-hero__overlay {
    height: 575px;
  }
}
.referral-hero__title-first {
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 1.5px;
  color: #42e8e0;
}

@media screen and (max-width: 767px) {
  .referral-hero__title-first {
    font-size: 20px;
  }
}
.referral-hero__title-second {
  color: #ffffff;
}

.referral-hero__title {
  font-weight: 900;
  font-size: 56px;
  line-height: 64px;
  text-align: center;
  color: #ffffff;
  padding: 10px;
}

@media screen and (max-width: 767px) {
  .referral-hero__title {
    font-size: 36px;
    line-height: 44px;
  }
}
.referral-hero__subtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #ffffff;
  width: 51%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 767px) {
  .referral-hero__subtitle {
    width: 100%;
  }
}
.referral-hero__caption {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #ffffffb2;
  width: 51%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 767px) {
  .referral-hero__caption {
    width: 100%;
  }
}
.referral-hero__auth-button {
  margin-top: 25px;
  padding: 14px 24px;
  background-color: #20bfb8;
  border-radius: 28px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.14), 0 2px 1px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.2);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.35px;
  color: #ffffff;
  transition: background-color 0.2s ease-in-out;
  text-align: center;
}

.referral-hero__auth-button:hover {
  background-color: #35ddd6;
}

.referral-hero__auth-button__font-color {
  background-color: #42e8e0;
}

.referral-hero__auth-button__font-color:hover {
  background-color: #71fff8;
}

.referral-content {
  margin-top: 100px;
}

@media screen and (max-width: 767px) {
  .referral-content {
    margin-top: 50px;
  }
}
.referral-content__container {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .referral-content__container {
    flex-direction: column-reverse;
  }
}
.referral-content__text-div {
  width: 50%;
  margin-left: 50px;
  overflow: hidden;
  padding: 10px 0px;
}

@media screen and (max-width: 767px) {
  .referral-content__text-div {
    width: 100%;
    margin-left: 0px;
  }
}
.referral-content__small-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #251f33;
  padding: 10px;
}

.referral-content__title {
  font-weight: 900;
  font-size: 40px;
  line-height: 47px;
  color: #251f33;
  padding: 10px;
}

@media screen and (max-width: 767px) {
  .referral-content__title {
    font-size: 28px;
    line-height: 33px;
  }
}
.referral-content__description {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #251f33;
  padding: 10px;
}

.referral-content__bold-text {
  font-weight: 600;
}

.referral-content__image-div {
  width: 50%;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .referral-content__image-div {
    width: 100%;
    margin-top: 50px;
  }
}
.referral-content__image-div__image {
  width: 710px;
  filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.12)) drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
}

.referral-instructions {
  background-color: #f4f4fb;
  margin-top: 100px;
  text-align: center;
  padding-bottom: 80px;
}

.referral-instructions__heading {
  font-weight: 900;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  color: #251f33;
  padding: 50px 0px;
}

@media screen and (max-width: 767px) {
  .referral-instructions__heading {
    font-size: 28px;
    line-height: 33px;
  }
}
.referral-instructions__instruction-div {
  text-align: center;
}

@media screen and (max-width: 767px) {
  .referral-instructions__instruction-div {
    margin-bottom: 30px;
  }
}
.referral-instructions__images-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .referral-instructions__images-div {
    display: block;
  }
}
.referral-instructions__image {
  max-width: 241px;
  height: 210px;
  display: block;
  margin: auto;
}

.referral-instructions__image-content {
  padding: 20px 0px;
}

.referral-instructions__image-content__number-div {
  background: #f4f4fb;
  border: 2px solid #5022a7;
  border-radius: 100%;
  width: 40px;
  text-align: center;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.referral-instructions__image-content__number {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #5022a7;
  margin: 6px 0px;
}

.referral-instructions__image-content__heading {
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  padding: 5px;
}

.referral-instructions__image-content__description {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  max-width: 290px;
  padding: 5px;
  margin-left: auto;
  margin-right: auto;
}

.marketing-content {
  text-align: center;
}

.marketing-content__heading-div {
  margin-top: 80px;
}

@media screen and (max-width: 767px) {
  .marketing-content__heading-div {
    margin-top: 70px;
  }
}
.marketing-content__heading-div__small-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #251f33;
  padding: 10px;
}

.marketing-content__heading-div__heading {
  font-weight: 900;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  color: #251f33;
  padding: 10px;
}

@media screen and (max-width: 767px) {
  .marketing-content__heading-div__heading {
    font-size: 28px;
    line-height: 33px;
  }
}
.marketing-content__images-div {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.marketing-content__image-div {
  max-width: 309px;
  position: relative;
  padding: 5px;
}

@media screen and (max-width: 767px) {
  .marketing-content__image-div {
    max-width: 50%;
  }
}
@media (min-width: 768px) and (max-width: 1023.95px) {
  .marketing-content__image-div {
    max-width: 50%;
  }
}
.marketing-content__image-div:hover {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
}

.marketing-content__play-icon-div {
  top: 5%;
  right: 5%;
  color: white;
  padding: 5px;
  position: absolute;
}

.marketing-content__play-icon {
  font-size: 28px;
}

.partner-program {
  background-color: #f4f4fb;
  margin-top: 100px;
}

@media screen and (max-width: 767px) {
  .partner-program {
    margin-top: 70px;
  }
}
.partner-program__container {
  display: flex;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

@media screen and (max-width: 767px) {
  .partner-program__container {
    flex-direction: column;
  }
}
.partner-program__text-div {
  width: 50%;
  overflow: hidden;
  padding: 10px 0px;
}

@media screen and (max-width: 767px) {
  .partner-program__text-div {
    width: 100%;
  }
}
.partner-program__text-div__small-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #251f33;
  padding: 10px;
}

.partner-program__text-div__heading {
  font-weight: 900;
  font-size: 40px;
  line-height: 47px;
  color: #251f33;
  padding: 10px;
}

@media screen and (max-width: 767px) {
  .partner-program__text-div__heading {
    font-size: 28px;
    line-height: 33px;
  }
}
.partner-program__text-div__description {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #251f33;
  padding: 10px;
}

.partner-program__image-div {
  width: 50%;
  margin-left: 50px;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .partner-program__image-div {
    width: 100%;
    margin-left: 0px;
    margin-top: 50px;
  }
}
.partner-program__image-div__image {
  filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.12)) drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
  width: 710px;
}

.bonus-earnings {
  margin-bottom: 80px;
  text-align: center;
}

.bonus-earnings__heading-div {
  margin-top: 40px;
}

.bonus-earnings__heading-div__small-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #251f33;
  padding: 10px;
}

.bonus-earnings__heading-div__heading {
  font-weight: 900;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  color: #251f33;
  padding: 10px 10px 30px 10px;
  width: 40%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 767px) {
  .bonus-earnings__heading-div__heading {
    width: 100%;
    font-size: 28px;
    line-height: 33px;
  }
}
.bonus-earnings__images-div {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.bonus-earnings__image-div {
  max-width: 309px;
  padding: 5px;
}

@media screen and (max-width: 767px) {
  .bonus-earnings__image-div {
    max-width: 50%;
  }
}
@media (min-width: 768px) and (max-width: 1023.95px) {
  .bonus-earnings__image-div {
    max-width: 50%;
  }
}
.bonus-earnings__image-div:hover {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
}

.page--referral {
  background-color: #ffffff !important;
}

.page__body.page--terms-and-conditions {
  background-color: #fff;
}

.terms-conditions__hero-holder {
  @apply max-w-7xl mx-auto py-16 sm:py-24 lg:px-8;
}
.terms-conditions__hero-inner {
  text-align: center;
}
.terms-conditions__hero-heading {
  @apply mt-1 font-extrabold text-gray-900 text-2xl sm:text-5xl sm:tracking-tight lg:text-6xl uppercase;
}
.terms-conditions__hero-caption {
  @apply text-xs font-semibold text-indigo-600 tracking-wide uppercase mt-3;
}
.terms-conditions__hero-subheading {
  @apply max-w-xl mt-5 mx-auto text-xl text-gray-500;
}
.terms-conditions__content {
  @apply flex flex-col items-center mt-8;
}
.terms-conditions__content-heading {
  @apply text-xl sm:text-2xl font-semibold tracking-wide uppercase mb-2;
}
.terms-conditions__content-section {
  @apply mb-12;
  max-width: 768px;
}
.terms-conditions__content-text {
  @apply text-xs sm:text-sm text-gray-600 leading-6 sm:leading-7;
}
.terms-conditions__content-link {
  @apply font-semibold text-indigo-900;
}

.pop-hero {
  background-color: #160056;
}
.pop-hero__container {
  display: flex;
  @apply pb-1;
}
.pop-hero__text {
  @apply grow;
  @apply flex justify-center items-start flex-col;
  padding: 30px 0;
  z-index: 1;
}
.pop-hero__text-heading, .pop-hero__text-subheading, .pop-hero__text-series-heading, .pop-hero__text-series-subheading {
  color: #fff;
}
.pop-hero__text-heading, .pop-hero__text-series-heading {
  color: #fff;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: 0.2px;
  margin-bottom: 4px;
}
@media screen and (max-width: 959.95px) {
  .pop-hero__text-heading, .pop-hero__text-series-heading {
    font-size: 24px;
    line-height: 30px;
    @apply mb-0;
  }
}
.pop-hero__text-subheading {
  margin-top: 5px;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 28px;
}
@media screen and (max-width: 959.95px) {
  .pop-hero__text-subheading {
    font-size: 14px;
  }
}
.pop-hero__text-series-heading {
  font-size: 28px;
  line-height: 38px;
}
@media screen and (max-width: 959.95px) {
  .pop-hero__text-series-heading {
    font-size: 20px;
    line-height: 30px;
    @apply mb-0;
  }
}
.pop-hero__text-series-subheading {
  font-size: 14px;
}
.pop-hero__text-stats {
  padding: 0;
  border: 1px solid rgba(255, 255, 255, 0.5);
  width: 550px;
  border-radius: 8px;
  display: flex;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1000000015px;
  text-align: center;
}
@media screen and (max-width: 959.95px) {
  .pop-hero__text-stats {
    width: 100%;
  }
}
.pop-hero__text-stat-column {
  text-align: center;
  width: 33%;
  padding: 10px;
  display: inline-grid;
}
.pop-hero__text-stat-column-value {
  font-weight: 500;
  font-size: 20px;
}
@media screen and (max-width: 959.95px) {
  .pop-hero__text-stat-column-value {
    font-size: 16px !important;
  }
}
.pop-hero__text-stat-column-title {
  font-weight: 400;
  font-size: 14px;
  color: #ffffffb2;
}
.pop-hero__text-stat-column.with-right-border {
  height: 72px;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
}
.pop-hero__splash {
  @apply w-4/12 flex justify-end items-center;
}
@media screen and (max-width: 599.95px) {
  .pop-hero__splash {
    @apply w-3/12;
  }
}
@media screen and (max-width: 599.95px) {
  .pop-hero__splash-image {
    max-width: 175px;
  }
}
.pop-hero__logo {
  @apply w-1/12 mr-1 flex justify-start items-center;
}
@media screen and (max-width: 599.95px) {
  .pop-hero__logo {
    @apply w-2/12;
  }
}
.pop-hero__logo-image--holder {
  @apply bg-white w-20 h-20 rounded flex items-center;
}
@media screen and (max-width: 599.95px) {
  .pop-hero__logo-image--holder {
    @apply w-16 h-16;
  }
}
.pop-hero__category-logo-image {
  @apply h-9 w-auto;
  margin: 11px 0 5px 0;
}
.pop-hero__breadcrumbs {
  @apply rounded text-sm font-sans w-full pt-7 pb-1;
}
@media screen and (max-width: 599.95px) {
  .pop-hero__breadcrumbs {
    @apply text-xs;
  }
}
.pop-hero__breadcrumbs__list {
  @apply flex list-none;
}
.pop-hero__breadcrumbs__list li {
  @apply text-white pr-1;
}
.pop-hero__breadcrumbs__list__link {
  @apply font-bold;
  letter-spacing: 0.2px;
  color: #42e8e0;
}

.pop-list {
  padding: 48px 0;
  background: #fff;
  flex: 1 1 auto;
  color: #000;
}
@media screen and (max-width: 959.95px) {
  .pop-list {
    padding-top: 24px;
    padding-bottom: 0;
  }
}
@media screen and (max-width: 959.95px) {
  .pop-list__container {
    padding: 0;
  }
}
.pop-list__table {
  @apply w-full relative;
  border-collapse: separate;
  border-spacing: 0;
  min-width: 900px;
  box-sizing: border-box;
}
@media screen and (max-width: 959.95px) {
  .pop-list__table {
    min-width: 1200px;
  }
}
@media screen and (max-width: 1279.95px) {
  .pop-list__table__container {
    padding-left: 0;
    padding-right: 0;
  }
}
.pop-list__table-holder {
  max-width: 100%;
  overflow-x: auto;
}
.pop-list__table-body .pop-list__table-row .pop-list__table-cell--series,
.pop-list__table-body .pop-list__table-row .pop-list__table-cell--card {
  @apply bg-white;
}
.pop-list__table-body .pop-list__table-row:nth-child(odd) {
  @apply bg-gray-100;
}
.pop-list__table-body .pop-list__table-row:nth-child(odd) .pop-list__table-cell--series,
.pop-list__table-body .pop-list__table-row:nth-child(odd) .pop-list__table-cell--card {
  @apply bg-gray-100;
}
.pop-list__table-total-row {
  @apply text-white;
  background-color: #20bfb8;
}
.pop-list__table-total-row .pop-list__table-cell--series,
.pop-list__table-total-row .pop-list__table-cell--card {
  background-color: #20bfb8;
}
.pop-list__table-cell {
  padding: 16px 8px;
  text-align: left;
  @apply text-xs;
}
.pop-list__table-cell--series, .pop-list__table-cell--card {
  @apply w-64 sticky left-0;
}
@media screen and (max-width: 959.95px) {
  .pop-list__table-cell--series, .pop-list__table-cell--card {
    @apply w-52 border-r-2 border-solid border-gray-300;
  }
}
.pop-list__table-cell--card-content {
  @apply flex;
}
.pop-list__table-cell--grade-title, .pop-list__table-cell--value, .pop-list__table-cell--total {
  @apply w-16;
  text-align: right;
}
.pop-list__table-head .pop-list__table-cell {
  padding-top: 20px;
  padding-bottom: 20px;
}
.pop-list__table-head .pop-list__table-cell--series, .pop-list__table-head .pop-list__table-cell--card {
  @apply bg-white;
}
.pop-list__table-total-row .pop-list__table-cell {
  padding-top: 32px;
  padding-bottom: 32px;
  @apply text-sm;
}
.pop-list__table-total-row .pop-list__table-cell .pop-list__table__info-text {
  @apply m-0;
}
.pop-list__table__info {
  @apply flex items-center justify-start;
}
.pop-list__table__info-image {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
}
.pop-list__table__info-text {
  @apply ml-2;
  max-width: 240px;
}
.pop-list__table__info-heading {
  @apply text-sm;
  font-weight: 500;
  line-height: 24px;
}
@media screen and (max-width: 959.95px) {
  .pop-list__table__info-heading.display-desktop {
    @apply hidden;
  }
}
.pop-list__table__info-heading.display-mobile {
  @apply hidden;
}
@media screen and (max-width: 959.95px) {
  .pop-list__table__info-heading.display-mobile {
    @apply block;
  }
}
.pop-list__table__info-subheading {
  font-size: 12px;
}
@media screen and (max-width: 959.95px) {
  .pop-list__table__info-subheading.display-desktop {
    @apply hidden;
  }
}
.pop-list__table__info-subheading.display-mobile {
  @apply hidden;
}
@media screen and (max-width: 959.95px) {
  .pop-list__table__info-subheading.display-mobile {
    @apply block;
  }
}
.pop-list__table__info-date {
  @apply hidden;
}
@media screen and (max-width: 959.95px) {
  .pop-list__table__info-date {
    @apply block pt-2 text-xs text-white text-opacity-70;
  }
}
.pop-list__grade {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  background-color: #42f9ff;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
}
.pop-list__grade__label {
  margin-bottom: 6px;
  font-size: 12px;
  line-height: 12px;
  color: rgba(0, 0, 0, 0.87);
}
.pop-list__grade__value {
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
}

.page--pop-list {
  background-color: #ffffff;
}

.pop-index-hero {
  height: 507px;
}
@media screen and (max-width: 959.95px) {
  .pop-index-hero {
    height: 331px;
  }
}
.pop-index-hero__container {
  background-image: url("/assets/images/pop-hero.jpg");
  height: 507px;
  overflow: hidden;
  text-align: center;
  color: #ffffff;
  background-size: cover;
  background-position: center;
}
@media screen and (max-width: 959.95px) {
  .pop-index-hero__container {
    height: 331px;
  }
}
.pop-index-hero__mask {
  display: flex;
  background-color: rgba(0, 0, 0, 0.6);
  height: 507px;
  text-align: center;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 959.95px) {
  .pop-index-hero__mask {
    height: 331px;
  }
}
.pop-index-hero__title {
  margin-top: -118px;
  font-family: Roboto, sans-serif;
  font-weight: 900;
  font-size: 3.75rem;
  line-height: 4.5rem;
  letter-spacing: 0.2px;
}
@media screen and (max-width: 959.95px) {
  .pop-index-hero__title {
    font-size: 34px;
    line-height: 46px;
  }
}
@media screen and (max-width: 499.95px) {
  .pop-index-hero__title {
    margin-top: -85px;
  }
}
@media screen and (max-width: 390.95px) {
  .pop-index-hero__title {
    margin-top: -64px;
  }
}
@media screen and (max-width: 339.95px) {
  .pop-index-hero__title {
    font-size: 28px;
    line-height: 38px;
  }
}
.pop-index-hero__subhead {
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  letter-spacing: 0.2px;
}
@media screen and (max-width: 959.95px) {
  .pop-index-hero__subhead {
    font-size: 16px;
    line-height: 24px;
  }
}
@media screen and (max-width: 339.95px) {
  .pop-index-hero__subhead {
    font-size: 12px;
    line-height: 16px;
  }
}

body {
  background-color: #f4f4fb !important;
}

.categories-list {
  margin-top: -118px;
}
@media screen and (max-width: 499.95px) {
  .categories-list {
    margin-top: -85px;
  }
}
@media screen and (max-width: 390.95px) {
  .categories-list {
    margin-top: -64px;
  }
}
.categories-list .row {
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem;
  justify-content: center;
  margin-bottom: 16px;
}
@media screen and (max-width: 599.95px) {
  .categories-list .row {
    gap: 12px;
    margin-bottom: 12px;
  }
}
@media screen and (max-width: 330.95px) {
  .categories-list .row {
    gap: 11px;
    margin-bottom: 11px;
  }
}

.category-box {
  display: flex;
  background: #ffffff;
  width: 23.5%;
  height: 236px;
  max-height: 228px;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.14), 0 2px 1px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  padding: 16px;
}
@media screen and (max-width: 1279.95px) {
  .category-box {
    width: 31%;
  }
}
@media screen and (max-width: 959.95px) {
  .category-box {
    width: 48%;
  }
}
@media screen and (max-width: 499.95px) {
  .category-box {
    height: 170px;
  }
}
@media screen and (max-width: 390.95px) {
  .category-box {
    height: 128px;
  }
}
.category-box-fade {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
  box-shadow: none;
}
.category-box .category-logo {
  height: auto;
  max-height: 100%;
}

.categories-coming-soon {
  width: 100%;
  height: 236px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
  bottom: 50px;
  padding: 80px 54px;
}
.categories-coming-soon div {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  /* or 120% */
  text-align: center;
  letter-spacing: 0.18px;
}
@media screen and (max-width: 599.95px) {
  .categories-coming-soon div {
    font-size: 14px;
    line-height: 18px;
  }
}

.coming-soon-row {
  position: relative;
  width: inherit;
  max-width: inherit;
}
.coming-soon-row .categories-coming-soon {
  position: absolute;
  top: 0;
}

@media screen and (max-width: 1279.95px) {
  .display-lg {
    display: none !important;
  }
}

.display-md {
  display: none !important;
}
@media screen and (max-width: 1279.95px) {
  .display-md {
    display: flex !important;
  }
}
@media screen and (max-width: 959.95px) {
  .display-md {
    display: none !important;
  }
}

.display-mobile {
  display: none !important;
}
@media screen and (max-width: 959.95px) {
  .display-mobile {
    display: flex !important;
  }
}

@media screen and (max-width: 1279.95px) {
  .hide-md {
    display: none !important;
  }
}
@media screen and (max-width: 959.95px) {
  .hide-md {
    display: flex !important;
  }
}

.category-card {
  background: white;
  width: 290px;
  display: flex;
  flex-direction: column;
  margin: auto;
  border-radius: 15px;
  text-align: center;
  border: 0 solid #e5e7eb;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.14), 0 2px 1px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 1279.95px) {
  .category-card {
    width: 85%;
    margin-top: 30px;
  }
}

.category-card__image {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  height: 175px !important;
  align-items: center;
}
.category-card__image .category-logo {
  max-width: 230px;
  height: auto;
  max-height: 70%;
}

.category-card--unit-stats {
  background: whitesmoke;
  color: black;
  font-weight: 700;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
}
.category-card--unit-stats .one-third {
  width: 33%;
  float: left;
  padding: 10px 15px;
}
.category-card--unit-stats .stat {
  position: relative;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1000000015px;
  text-align: center;
}
.category-card--unit-stats .stat-value {
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1000000015px;
  text-align: center;
}
.category-card--unit-stats .no-border {
  border-right: none;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.Home-sectionColumn--quarter {
  width: 25%;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 1279px) {
  .Home-sectionColumn--quarter {
    width: 50%;
  }
}
@media screen and (max-width: 599px) {
  .Home-sectionColumn--quarter {
    width: 100%;
  }
}
.Home-sectionColumn--three-quarter {
  width: 75%;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 1279px) {
  .Home-sectionColumn--three-quarter {
    width: 50%;
  }
}
@media screen and (max-width: 599px) {
  .Home-sectionColumn--three-quarter {
    width: 100%;
  }
}
.Home-sectionColumn--two-thirds {
  padding: 0 30px;
  width: 66.66666%;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 1279px) {
  .Home-sectionColumn--two-thirds {
    width: 50%;
  }
}
@media screen and (max-width: 599px) {
  .Home-sectionColumn--two-thirds {
    width: 100%;
  }
}
.Home-sectionColumn--no-padding {
  padding: 0;
}

.Home-sectionColumn--start-center,
.Home-sectionContainer--start-center {
  align-items: start;
  justify-content: center;
}

.Home-hero-uk {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #fff;
}
@media screen and (max-width: 767px) {
  .Home-hero-uk {
    min-height: 70vh;
  }
}

.Home-hero-ukContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: end;
}
.Home-hero-ukContainer--alignStart {
  align-items: start;
}

@media screen and (min-width: 1600px) {
  .Home-hero-ukContainer {
    max-width: 1330px;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1599px) {
  .Home-hero-ukContainer {
    max-width: 100%;
  }
}
.Home-hero-ukTitle,
.Home-hero-ukSubtitle {
  color: #000;
}

@media screen and (max-width: 767px) {
  .Home-hero-ukContainer .Home-sectionColumn {
    padding: 0;
  }
}

.Home-hero-ukTitle {
  font-weight: 300;
  font-size: 60px;
  line-height: 60px;
  letter-spacing: 0;
  text-align: center;
  max-width: 840px;
  margin-bottom: 62px;
}
@media screen and (max-width: 767px) {
  .Home-hero-ukTitle {
    font-size: 26px;
    line-height: 26px;
    margin-bottom: 79px;
  }
}
.Home-hero-ukTitle b {
  font-weight: 500;
}

.Home-hero-ukSubtitle {
  font-weight: 400;
  font-size: 36px;
  line-height: 36px;
  letter-spacing: 0;
  text-align: center;
  max-width: 900px;
}
@media screen and (max-width: 767px) {
  .Home-hero-ukSubtitle {
    font-size: 16px;
    line-height: 16px;
    padding: 0 40px;
  }
}

.Home-hero-ukCtaRow {
  margin-top: 141px;
  display: flex;
  justify-content: center;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .Home-hero-ukCtaRow {
    flex-direction: column;
    margin-top: 68px;
  }
}

.Home-ukCtaButtonContainer {
  width: 30%;
}
@media screen and (max-width: 767px) {
  .Home-ukCtaButtonContainer {
    width: 100%;
    padding-left: 56px;
    padding-right: 56px;
  }
}

.Home-ukButtonCta {
  width: 100%;
  height: 72px;
  border-radius: 40px;
  background-color: #30016e;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0;
  color: #fff;
  text-transform: capitalize;
}
.Home-ukButtonCta:hover {
  background-color: #30016e;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .Home-ukButtonCta {
    width: 100%;
    height: 47px;
    font-size: 16px;
    line-height: 16px;
  }
}

.Home-hero-whyAgsCta {
  width: 100%;
  height: 72px;
  border-radius: 40px;
  border-width: 1px;
  border-color: #30016e;
  font-family: "Ara", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0;
  margin-left: 50px;
}
@media screen and (max-width: 767px) {
  .Home-hero-whyAgsCta {
    width: 100%;
    margin-left: 0;
    margin-top: 15px;
    height: 47px;
    font-size: 16px;
    line-height: 16px;
  }
}

@media screen and (max-width: 767px) {
  .Home-ukHeroImage {
    display: none;
  }
}

.Home-ukTechnology.Home-section {
  padding-top: 0;
  padding-bottom: 20px;
}
@media screen and (max-width: 767px) {
  .Home-ukTechnology {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
    min-height: 0;
  }
}
@media screen and (max-width: 767px) {
  .Home-ukTechnology .Home-hero-ukTitle {
    font-size: 26px;
    line-height: 26px;
    text-align: left;
    max-width: 90%;
  }
}
.Home-ukTechnology .Home-hero-ukTitle span {
  font-size: 60px;
}
@media screen and (max-width: 767px) {
  .Home-ukTechnology .Home-hero-ukTitle span {
    font-size: 26px;
  }
}
@media screen and (max-width: 767px) {
  .Home-ukTechnology .Home-hero-ukCtaRow {
    display: none;
  }
}

.Home-ukStats .container-fluid {
  padding: 0 85px;
}
@media screen and (max-width: 767px) {
  .Home-ukStats .container-fluid {
    padding: 0 10px;
  }
}
@media screen and (max-width: 767px) {
  .Home-ukStats {
    min-height: 0;
    padding-top: 0;
    padding-bottom: 50px;
  }
}

.Home-ukStatsTitle {
  font-weight: 300;
  font-size: 55px;
  line-height: 55px;
  letter-spacing: 0;
  text-align: center;
}
.Home-ukStatsTitle b {
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .Home-ukStatsTitle {
    display: none;
  }
}

.Home-ukStatsCardsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;
  margin-top: 98px;
}
@media screen and (max-width: 767px) {
  .Home-ukStatsCardsContainer {
    flex-direction: column;
  }
}
.Home-ukStatsCardsContainer .Home-ukStatsCard {
  width: 100%;
  max-width: 290px;
  border-radius: 26px;
  background-color: #30016e;
  color: #fff;
  padding: 28px 14px;
  margin: 0 30px;
  letter-spacing: 0;
  text-align: center;
}
.Home-ukStatsCardsContainer .Home-ukStatsCard--quarter {
  width: 25%;
}
@media screen and (max-width: 767px) {
  .Home-ukStatsCardsContainer .Home-ukStatsCard--quarter {
    width: 100%;
    margin: 0 0 30px 0;
  }
}
.Home-ukStatsCardsContainer .Home-ukStatsCard .Home-ukStatsCard-Title {
  font-weight: 300;
  font-size: 30px;
  line-height: 30px;
}
.Home-ukStatsCardsContainer .Home-ukStatsCard .Home-ukStatsCard-Title b {
  font-weight: 500;
}
.Home-ukStatsCardsContainer .Home-ukStatsCard .Home-ukStatsCard-Value {
  font-weight: 500;
  font-size: 60px;
  line-height: 60px;
  margin: 40px 0;
}
.Home-ukStatsCardsContainer .Home-ukStatsCard .Home-ukStatsCard-BottomText {
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
}

.Home-uk-cardsGraded {
  background-color: #30016e;
  color: #ffffff;
  padding-top: 100px;
  padding-bottom: 100px;
  font-weight: 300;
  letter-spacing: 0;
  text-align: center;
}
.Home-uk-cardsGraded .container {
  justify-content: center;
  align-items: center;
  display: flex;
}
.Home-uk-cardsGraded .container .Home-sectionColumn--half--left.Home-sectionColumn {
  padding: 0;
}
.Home-uk-cardsGraded .Home-uk-cardsGradedText {
  font-size: 50px;
  line-height: 60px;
}
@media screen and (max-width: 767px) {
  .Home-uk-cardsGraded .Home-uk-cardsGradedText {
    font-size: 24px;
    line-height: 28px;
  }
}
.Home-uk-cardsGraded .Home-uk-cardsGradedChartColumn {
  align-items: end;
}
.Home-uk-cardsGraded .Home-uk-cardsGradedChartColumn .Home-uk-cardsGradedChartTitle {
  font-size: 28px;
  line-height: 28px;
  margin-bottom: 58px;
}
@media screen and (max-width: 767px) {
  .Home-uk-cardsGraded .Home-uk-cardsGradedChartColumn .Home-uk-cardsGradedChartTitle {
    font-size: 20px;
    line-height: 28px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.Home-uk-cardsGraded .Home-uk-cardsGradedChartColumn .Home-uk-cardsGradedChartContainer {
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: 75%;
}
.Home-uk-cardsGraded .Home-uk-cardsGradedChartColumn .Home-uk-cardsGradedChartContainer .Home-uk-cardsGradedBarContainer {
  margin-left: 52px;
  display: flex;
  flex-direction: column;
  justify-content: end;
}
.Home-uk-cardsGraded .Home-uk-cardsGradedChartColumn .Home-uk-cardsGradedChartContainer .Home-uk-cardsGradedBarContainer .Home-uk-cardsGradedBarValue,
.Home-uk-cardsGraded .Home-uk-cardsGradedChartColumn .Home-uk-cardsGradedChartContainer .Home-uk-cardsGradedBarContainer .Home-uk-cardsGradedBarDate {
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  margin-top: 4px;
  margin-bottom: 4px;
}
.Home-uk-cardsGraded .Home-uk-cardsGradedChartColumn .Home-uk-cardsGradedChartContainer .Home-uk-cardsGradedBarContainer .Home-uk-cardsGradedBar {
  width: 76px;
  background-color: #d9d9d9;
  content: "";
}
.Home-uk-cardsGraded .Home-uk-cardsGradedChartColumn .Home-uk-cardsGradedChartContainer .Home-uk-cardsGradedBarContainer .Home-uk-cardsGradedBar--first {
  height: 109px;
}
.Home-uk-cardsGraded .Home-uk-cardsGradedChartColumn .Home-uk-cardsGradedChartContainer .Home-uk-cardsGradedBarContainer .Home-uk-cardsGradedBar--second {
  height: 190px;
}
.Home-uk-cardsGraded .Home-uk-cardsGradedChartColumn .Home-uk-cardsGradedChartContainer .Home-uk-cardsGradedBarContainer .Home-uk-cardsGradedBar--third {
  height: 307px;
}
.Home-uk-cardsGraded .Home-hero-ukCtaRow {
  margin-top: 58px;
}
.Home-uk-cardsGraded .Home-hero-ukCtaRow .Home-ukButtonCta,
.Home-uk-cardsGraded .Home-hero-ukCtaRow .Home-hero-whyAgsCta {
  color: #30016e;
  background-color: #ffffff;
}
.Home-uk-cardsGraded .Home-hero-ukCtaRow .Home-ukButtonCta:hover,
.Home-uk-cardsGraded .Home-hero-ukCtaRow .Home-hero-whyAgsCta:hover {
  color: #30016e;
  background-color: #ffffff;
}

@media screen and (max-width: 599px) {
  .Home-ukMobileApps {
    padding-top: 56px;
    padding-bottom: 95px;
  }
}

.Home-ukMobileApps-title {
  font-weight: 800;
  font-size: 36px;
  line-height: 36px;
  letter-spacing: 0;
  text-align: center;
}
@media screen and (max-width: 599px) {
  .Home-ukMobileApps-title {
    font-size: 21px;
    line-height: 21px;
  }
}

.Home-ukMobileApps-linksRow {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}
@media screen and (max-width: 599px) {
  .Home-ukMobileApps-linksRow {
    margin-top: 95px;
  }
}
.Home-ukMobileApps-linksRow .Home-ukMobileApps-storeButtonLink {
  width: 35%;
  margin: 0 21.5px;
}
@media screen and (max-width: 599px) {
  .Home-ukMobileApps-linksRow .Home-ukMobileApps-storeButtonLink {
    margin: 0 7.5px;
  }
}
.Home-ukMobileApps-linksRow .Home-ukMobileApps-storeButtonLink .Home-ukMobileApps-storeButton {
  height: auto;
  border-radius: 10px;
}

@media screen and (max-width: 599px) {
  .Home-ukMobileAppsImageContainer {
    width: 100%;
    margin: 38px 58px 0 58px;
  }
}

.Home-ukMobileAppsImage {
  margin-top: -40px;
}
@media screen and (max-width: 599px) {
  .Home-ukMobileAppsImage {
    margin-top: 0;
  }
}

.Home-ukReady {
  background-color: #fff;
  padding-top: 160px;
  padding-bottom: 130px;
}
.Home-ukReady .Home-sectionContentText {
  margin-bottom: 0;
}
.Home-ukReady .Home-sectionContentHeadline {
  font-size: 24px;
}
@media screen and (max-width: 599px) {
  .Home-ukReady {
    padding-top: 20px;
    padding-bottom: 60px;
  }
}

.Home-ukReadyTitle {
  font-weight: 500;
  font-size: 50px;
  line-height: 50px;
  letter-spacing: 0;
}
@media screen and (max-width: 599px) {
  .Home-ukReadyTitle {
    font-size: 26px;
    line-height: 26px;
  }
}

.Home-ukReadyLink {
  font-weight: 500;
  font-size: 60px;
  line-height: 80px;
  letter-spacing: 0;
  color: #30016e;
  margin-left: 39px;
  margin-bottom: 35px;
}
@media screen and (max-width: 599px) {
  .Home-ukReadyLink {
    font-size: 20px;
    line-height: 26px;
    margin: 0;
  }
}

.Home-ukTestimonials {
  letter-spacing: 0;
  text-align: center;
  padding: 100px 0;
}
@media screen and (max-width: 768px) {
  .Home-ukTestimonials {
    padding: 50px 0;
  }
}
.Home-ukTestimonials .Home-ukTestimonials-slick {
  width: 100%;
  display: block;
}
@media screen and (max-width: 768px) {
  .Home-ukTestimonials .Home-ukTestimonials-slick {
    margin-top: 54px;
  }
}
.Home-ukTestimonials .Home-ukTestimonial {
  width: 100%;
  display: flex;
}
@media screen and (max-width: 768px) {
  .Home-ukTestimonials .Home-ukTestimonial.slick-slide {
    flex-direction: column !important;
  }
}
.Home-ukTestimonials .Home-ukTestimonialImageContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  width: 33.33333%;
}
@media screen and (max-width: 768px) {
  .Home-ukTestimonials .Home-ukTestimonialImageContainer {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}
.Home-ukTestimonials .Home-ukTestimonialContentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  width: 66.66666%;
}
@media screen and (max-width: 768px) {
  .Home-ukTestimonials .Home-ukTestimonialContentContainer {
    width: 100%;
  }
}
.Home-ukTestimonials .Home-ukTestimonialsImage {
  height: 406px;
  width: auto;
  border-radius: 20px;
}
@media screen and (max-width: 768px) {
  .Home-ukTestimonials .Home-ukTestimonialsImage {
    height: 223px;
  }
}
.Home-ukTestimonials .Home-ukTestimonial.slick-slide {
  display: flex;
  flex-direction: row;
}
.Home-ukTestimonials .Home-ukTestimonials-name,
.Home-ukTestimonials .Home-ukTestimonials-title,
.Home-ukTestimonials .Home-ukTestimonials-content {
  font-size: 26px;
  line-height: 26px;
}
@media screen and (max-width: 768px) {
  .Home-ukTestimonials .Home-ukTestimonials-name,
.Home-ukTestimonials .Home-ukTestimonials-title,
.Home-ukTestimonials .Home-ukTestimonials-content {
    font-size: 15px;
    line-height: 15px;
  }
}
.Home-ukTestimonials-name {
  font-weight: 500;
}
@media screen and (max-width: 768px) {
  .Home-ukTestimonials-name {
    margin-top: 8px;
  }
}
.Home-ukTestimonials-title {
  font-weight: 300;
}
.Home-ukTestimonials-content {
  font-weight: 300;
  margin-top: 58px;
  margin-bottom: 47px;
  max-width: 600px;
}
.Home-ukTestimonials .arrows .material-icons {
  font-size: 65px;
  margin: 0 64.5px;
  font-variation-settings: "FILL" 0, "wght" 200, "GRAD" 0, "opsz" 58;
}

.Pricing-uk {
  background-color: #fff;
  letter-spacing: 0;
  text-align: center;
  padding-top: 92px;
  padding-bottom: 140px;
}
.Pricing-uk .Pricing-uk-title {
  font-weight: 500;
  font-size: 48px;
  line-height: 48px;
}
@media screen and (max-width: 599px) {
  .Pricing-uk .Pricing-uk-title {
    font-size: 26px;
    line-height: 26px;
  }
}