.Home-sectionColumn {
    &--quarter {
        width: 25%;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 1279px) {
            width: 50%;
        }
        @media screen and (max-width: 599px) {
            width: 100%;
        }
    }

    &--three-quarter {
        width: 75%;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 1279px) {
            width: 50%;
        }
        @media screen and (max-width: 599px) {
            width: 100%;
        }
    }

    &--two-thirds {
        padding: 0 30px;
        width: 66.66666%;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 1279px) {
            width: 50%;
        }
        @media screen and (max-width: 599px) {
            width: 100%;
        }
    }

    &--no-padding {
        padding: 0;
    }
}

.Home-sectionColumn,
.Home-sectionContainer {
    &--start-center {
        align-items: start;
        justify-content: center;
    }
}
