@use '../../shared/utils';
@use '../../shared/breakpoints';

.pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 24px 0;
    &__limit {
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;
        @apply pr-10;

        @include breakpoints.down(sm) {
            padding: 0;
        }
        &__label,
        &__select {
            font-size: 14px;
            line-height: 14px;
            color: inherit;

            option {
                @apply text-black;
            }
        }
        &__select {
            margin-left: 8px;
            background-color: transparent;
            border: none;
            text-align: right;
        }
    }

    &__navigation {
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;
        &__pages {
            margin-right: 7px;
        }
        &__button {
            display: inline-flex;
            padding: 2px;
            color: inherit;
            position: relative;
            margin: 3px;

            &__disabled {
                pointer-events: none;
            }

            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                background-color: currentColor;
                opacity: 0;
                transition: utils.transition(opacity);
            }

            &,
            &:after {
                width: 28px;
                height: 28px;
                border-radius: 14px;
            }

            &:hover {
                &:after {
                    opacity: 0.2;
                }
            }
            &:active {
                &:after {
                    opacity: 0.4;
                }
            }
        }
    }
}
