.Home-uk-cardsGraded {
    background-color: #30016e;
    color: #ffffff;
    padding-top: 100px;
    padding-bottom: 100px;

    font-weight: 300;
    letter-spacing: 0;
    text-align: center;

    .container {
        justify-content: center;
        align-items: center;
        display: flex;

        .Home-sectionColumn--half--left {
            &.Home-sectionColumn {
                padding: 0;
            }
        }
    }

    .Home-uk-cardsGradedText {
        font-size: 50px;
        line-height: 60px;

        @media screen and (max-width: 767px) {
            font-size: 24px;
            line-height: 28px;
        }
    }

    .Home-uk-cardsGradedChartColumn {
        align-items: end;

        .Home-uk-cardsGradedChartTitle {
            font-size: 28px;
            line-height: 28px;
            margin-bottom: 58px;
            @media screen and (max-width: 767px) {
                font-size: 20px;
                line-height: 28px;
                margin-top: 20px;
                margin-bottom: 20px;
            }
        }

        .Home-uk-cardsGradedChartContainer {
            display: flex;
            flex-direction: row;
            justify-content: end;
            width: 75%;

            .Home-uk-cardsGradedBarContainer {
                margin-left: 52px;
                display: flex;
                flex-direction: column;
                justify-content: end;

                .Home-uk-cardsGradedBarValue,
                .Home-uk-cardsGradedBarDate {
                    font-size: 20px;
                    line-height: 20px;
                    text-align: center;
                    margin-top: 4px;
                    margin-bottom: 4px;
                }

                .Home-uk-cardsGradedBar {
                    width: 76px;
                    background-color: #d9d9d9;
                    content: '';

                    &--first {
                        height: 109px;
                    }
                    &--second {
                        height: 190px;
                    }
                    &--third {
                        height: 307px;
                    }
                }
            }
        }
    }

    .Home-hero-ukCtaRow {
        margin-top: 58px;

        .Home-ukButtonCta,
        .Home-hero-whyAgsCta {
            color: #30016e;
            background-color: #ffffff;

            &:hover {
                color: #30016e;
                background-color: #ffffff;
            }
        }
    }
}
