.Home-ukStats {
    .container-fluid {
        padding: 0 85px;
        @media screen and (max-width: 767px) {
            padding: 0 10px;
        }
    }
    @media screen and (max-width: 767px) {
        min-height: 0;
        padding-top: 0;
        padding-bottom: 50px;
    }
}
.Home-ukStatsTitle {
    font-weight: 300;
    font-size: 55px;
    line-height: 55px;
    letter-spacing: 0;
    text-align: center;

    b {
        font-weight: 500;
    }
    @media screen and (max-width: 767px) {
        display: none;
    }
}

.Home-ukStatsCardsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0;
    margin-top: 98px;

    @media screen and (max-width: 767px) {
        flex-direction: column;
    }

    .Home-ukStatsCard {
        width: 100%;
        max-width: 290px;
        border-radius: 26px;
        background-color: #30016e;
        color: #fff;
        padding: 28px 14px;
        margin: 0 30px;

        letter-spacing: 0;
        text-align: center;

        &--quarter {
            width: 25%;
            @media screen and (max-width: 767px) {
                width: 100%;
                margin: 0 0 30px 0;
            }
        }

        .Home-ukStatsCard-Title {
            font-weight: 300;
            font-size: 30px;
            line-height: 30px;

            b {
                font-weight: 500;
            }
        }

        .Home-ukStatsCard-Value {
            font-weight: 500;
            font-size: 60px;
            line-height: 60px;
            margin: 40px 0;
        }

        .Home-ukStatsCard-BottomText {
            font-weight: 400;
            font-size: 20px;
            line-height: 20px;
        }
    }
}
