@use '../../../shared/theme';
@use '../../../shared/utils';
@use '../../../shared/breakpoints';
@use '../../../shared/mixins';

.feed-view {
    &__warning {
        color: white;
        background-color: red;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 24px;
        font-size: 16px;
        font-weight: bold;
    }

    &__table-div-left {
        width: 320px;
        display: table-row;

        @include breakpoints.down(768) {
            display: none;
        }

        @include breakpoints.down(1024) {
            width: 100%;
            height: 100%;
        }
    }

    &__table-div-right {
        width: 320px;

        @include breakpoints.down(1024) {
            width: 100%;
            height: 100%;
        }
    }

    &__conclusion {
        @include breakpoints.down(768) {
            background: #f4f4fb;
            padding-top: 40px;
            margin-top: 50px;
        }

        &__button {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 30px 0px;

            .MuiButton-text {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                text-align: center;
                letter-spacing: 0.35px;
                color: #ffffff;
                background: #20bfb8;
                border-radius: 28px;
                padding: 15px 20px;
            }
        }

        &__text {
            padding: 60px;

            @include breakpoints.down(768) {
                padding: 0px;
            }

            &__heading {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                letter-spacing: 1.5px;
                text-transform: uppercase;
                color: rgba(0, 0, 0, 0.87);
                margin-bottom: 20px;
            }

            &__subHeading {
                font-weight: 900;
                font-size: 40px;
                line-height: 47px;
                text-align: center;
                color: rgba(0, 0, 0, 0.87);

                @include breakpoints.down(768) {
                    font-size: 24px;
                    line-height: 38px;
                }
            }
        }
    }

    &__search-card {
        position: relative;
        background-color: #5b35ab;

        .container {
            display: flex;
            justify-content: center;
        }

        @include breakpoints.down(768) {
            background-color: #5b35ab;
            padding-top: 100px;
            padding-bottom: 60px;
        }

        &__text {
            padding: 110px;
            display: block;

            @include breakpoints.down(768) {
                padding: 0px;
            }

            &__subHeading {
                font-weight: 900;
                font-size: 32px;
                line-height: 47px;
                text-align: center;
                color: white;

                @include breakpoints.down(768) {
                    margin-bottom: 20px;
                    font-size: 28px;
                    line-height: 38px;
                }
            }
        }
    }

    &__searchCardsBackgroundLeft {
        height: 96%;
        width: 36.5%;
        top: 10px;

        @include breakpoints.down(768) {
            margin-left: -10px;
        }
    }

    &__searchCardsBackgroundRight {
        right: 0;
        height: 96%;
        width: 36.5%;
        top: 10px;

        @include breakpoints.down(768) {
            margin-right: -10px;
        }
    }

    &__searchCardsBackgroundLeft,
    &__searchCardsBackgroundRight {
        position: absolute;

        @include breakpoints.down(768) {
            height: 40%;
            width: 50%;
            bottom: 0;
            top: unset;
            margin-top: 10px;
        }
    }

    &__graph {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #fff;
        padding-bottom: 60px;

        .apexcharts-bar-area:hover {
            fill: #20bfb8;
            box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12),
                0px 2px 4px rgba(0, 0, 0, 0.2);
        }

        &__container {
            @include breakpoints.down(lg) {
                overflow: scroll;
            }
        }

        &__content {
            .apexcharts-title-text {
                padding: 20px !important;
            }
        }

        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: rgb(244, 244, 251);
            padding: 20px;

            @include breakpoints.down(768) {
                width: 100%;
            }
        }

        &__title {
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: rgba(0, 0, 0, 0.87);
        }

        &__subTitle {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.1px;
            color: #ffffff;
            background: #20bfb8;
            border-radius: 4px;
            padding: 10px 20px;
        }
    }

    &__share {
        background: #f4f4fb;
        border-radius: 8px;
        width: 320px;
        height: 80px;
        padding: 15px;

        @include breakpoints.down(1024) {
            width: 100%;
            height: 100%;
        }

        &__title {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            color: rgba(0, 0, 0, 0.54);
        }

        &__icon {
            color: rgba(0, 0, 0, 0.54);
            padding-top: 10px;
            padding-bottom: 5px;
        }

        &__icon:hover {
            cursor: pointer;
        }
    }

    &__grading-standard {
        display: flex;
        margin: 10px 0px;

        &:hover {
            cursor: pointer;
        }

        &__icon-div {
            width: 30px;
        }

        &__text {
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.2px;
            color: rgba(66, 232, 224, 1);
            margin-top: 2px;
        }

        .material-icons {
            font-weight: 500;
            color: rgba(66, 232, 224, 1);
        }
    }

    &__ags-population {
        background: #f4f4fb;
        border-radius: 8px;
        width: 320px;
        height: 80px;
        display: flex;
        justify-content: space-between;
        padding: 15px;

        @include breakpoints.down(1024) {
            width: 100%;
            height: 100%;
            padding: 10px;
        }

        @include breakpoints.down(768) {
            width: 100%;
            padding: 15px !important;
        }

        &__count {
            font-weight: 800;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 0.15px;
            color: #251f33;
            padding-top: 10px;
        }

        &__title {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            color: rgba(0, 0, 0, 0.54);
        }

        &__icon:hover {
            cursor: pointer;
        }

        &__icon {
            display: flex;

            &__text {
                margin-top: 2px;
                margin-left: 2px;
                color: #20bfb8;
            }

            .material-icons {
                color: #20bfb8;
            }
        }
    }

    &__right-side {
        display: flex;
        justify-content: space-between;

        @include breakpoints.down(768) {
            margin-left: 0px;
        }
    }

    &__left-side {
        display: flex;
        justify-content: space-between;
    }

    &__share {
        @include breakpoints.down(768) {
            display: none;
        }
    }

    &__generated-images {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;

        &__actions {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__text {
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: rgba(0, 0, 0, 0.87);
            padding: 20px;
        }

        &__front {
            display: flex;
            align-items: center;
            justify-content: space-around;
            margin: 40px 0px;

            &__image {
                width: 403px;
                height: auto;
                background: #000;
                filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.14)) drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.12))
                    drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2));
                border-radius: 8px;
                transform: rotate(90deg);
                margin: 0px;
            }
        }

        &__back {
            display: none;

            &__image {
                width: 20%;
            }
        }

        &__buttons {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            &__front {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.2px;
                color: #20bfb8;
                background: rgba(32, 191, 184, 0.08);
                border: 1px solid #20bfb8;
                border-radius: 24px;
                padding: 10px 20px;
                margin: 5px;
            }

            &__back {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.2px;
                color: rgba(0, 0, 0, 0.54);
                background: #ffffff;
                border: 1px solid rgba(0, 0, 0, 0.18);
                border-radius: 24px;
                padding: 10px 20px;
                margin: 5px;
            }
        }
    }

    &__header {
        position: relative;
        overflow: hidden;
        padding-top: 86px;

        @include breakpoints.down(768) {
            padding-top: 0;
        }

        &__overlay {
            z-index: 1;

            &,
            &-background,
            &-color {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                height: 100%;
                width: 100%;
                background: black;
                overflow: hidden;
                background: linear-gradient(rgba(37, 31, 51, 0.7), rgba(37, 31, 51, 0.7));
            }

            &-background {
                z-index: 1;
                object-fit: cover;
                object-position: center center;
            }

            &-color {
                z-index: 3;
            }
        }

        &__container {
            position: relative;
            z-index: 1;
            display: flex;
        }

        &__content {
            min-height: 142px;
            color: #fff;
            display: flex;
            align-items: center;
            width: 100%;

            @include breakpoints.down(768) {
                max-width: 100%;
                margin-bottom: 50%;
                margin-top: 20px;
            }

            @include breakpoints.down(xs) {
                padding-left: 0;
                padding-bottom: 14px;
                padding-top: 14px;
            }
        }

        &__details {
            width: 100%;
            flex: 1 1 auto;
            padding-right: 14px;
        }

        &__certificate {
            color: rgba(255, 255, 255, 0.7);
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.7px;
            text-transform: uppercase;
            margin-bottom: 12px;

            @include breakpoints.down(768) {
                margin-bottom: 30px;
            }
        }

        &__share {
            align-items: center;
            justify-content: flex-end;
            margin-bottom: 35px;
            display: none;

            @include breakpoints.down(xs) {
                display: flex;
            }
        }

        &__heading,
        &__subheading {
            color: #fff;
        }

        &__heading {
            font-size: 34px;
            line-height: 40px;
            margin-bottom: 6px;
            font-weight: 700;

            @include breakpoints.down(xs) {
                font-size: 24px !important;
            }

            @include breakpoints.down(1024) {
                font-size: 30px;
            }
        }

        &__subheading {
            font-size: 16px;
            line-height: 24px;

            @include breakpoints.down(xs) {
                font-size: 14px !important;
            }

            @include breakpoints.down(1024) {
                font-size: 14px;
            }
        }

        &__score {
            display: inline-flex;
            justify-content: flex-end;
        }

        &__grade {
            width: 100px;
            height: 90px;
            background: #20bfb8;
            border-radius: 8px;
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            @include breakpoints.down(xs) {
                padding: 10px 14px;
                min-width: 71px;
                min-height: 82px;
            }

            &-label,
            &-score {
                text-align: center;
                color: rgba(255, 255, 255, 0.87);
            }

            &-label {
                font-weight: 400;
                font-size: 14px;
                line-height: 12px;
                text-align: center;
                letter-spacing: 0.5px;
                color: #ffffff;
                margin-bottom: 6px;
                text-transform: uppercase;

                @include breakpoints.down(xs) {
                    font-size: 12px;
                    margin-bottom: 0;
                }
            }

            &-score {
                font-weight: 900;
                font-size: 44px;
                line-height: 52px;
                text-align: center;
                color: #ffffff;

                @include breakpoints.down(xs) {
                    font-size: 40px;
                }
            }
        }

        .feed-view__card-holder {
            @include breakpoints.down(768) {
                display: none;
            }
        }
    }

    &__card {
        .container {
            width: 546px;
        }

        &-holder {
            width: 92%;

            @include breakpoints.down(768) {
                width: 100%;
            }
        }

        &-image {
            width: 50%;
            border-radius: 4%;
            margin: auto;
            box-shadow: 0 2px 2px rgba(0, 0, 0, 0.14), 0 3px 1px rgba(0, 0, 0, 0.12), 0 1px 5px rgba(0, 0, 0, 0.2);
        }

        .swiper-slide {
            text-align: center;
            font-size: 18px;
            background: #fff;
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;
        }

        .swiper-slide img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .thumbNail {
            justify-content: center;
        }

        .swiper-slide {
            background-size: cover;
            background-position: center;
        }

        .swiper-slide:hover {
            cursor: pointer;
        }

        .mySwiper {
            margin-top: 20px;
            height: 20%;
            box-sizing: border-box;
            padding: 10px 0;
        }

        .mySwiper .swiper-slide {
            width: 50px !important;
            height: 100%;
            opacity: 0.4;
        }

        .mySwiper .swiper-slide-thumb-active {
            opacity: 1;
        }

        .swiper-slide img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__content {
        z-index: 3;
        background-color: #fff;

        @include breakpoints.down(768) {
            z-index: 2;
        }

        &__container {
            display: flex;

            @include breakpoints.down(768) {
                flex-direction: column;
            }
        }

        &__card-holder {
            margin-top: -170px;
            background: #f9f9f9;
            border: 1px solid #e0e0e0;
            border-radius: 12px;
            width: 546px;
            height: 600px;
            padding: 30px 0px;
            display: flex;
            align-items: center;

            @include breakpoints.down(768) {
                width: 100% !important;
                height: 100% !important;
                display: block;
            }

            @include breakpoints.down(1024) {
                width: 340px;
                height: 450px;
                display: block;
            }
        }
    }

    &__details {
        padding-top: 24px;
        width: 100%;
        margin-left: 50px;

        @include breakpoints.down(md) {
            flex-direction: column;
            margin-left: 20px;
        }

        @include breakpoints.down(768) {
            padding-left: 0;
            margin-left: 0px;
        }

        &-col {
            @include breakpoints.down(768) {
                padding: 0;
                max-width: 100%;
                display: block !important;
            }

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }
    }

    &__table {
        width: 100%;
        margin-top: 30px;

        &__mobile-content {
            display: none;

            @include breakpoints.down(768) {
                display: table-row;
            }
        }

        &-cell {
            font-size: 14px;
            line-height: 24px;
            color: rgba(0, 0, 0, 0.87);
            padding-left: 24px;
            width: 200px;

            &--heading {
                width: auto;
                padding-left: 0;
                font-weight: 500;
            }
        }

        &__request-details {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.35px;
            color: theme.$primaryColorDark;
            transition: utils.transition(color);

            &:hover {
                color: theme.$primaryColor;
            }
        }
    }

    &__breakdown,
    &__images {
        &__top-section {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            margin-left: 10px;

            @include breakpoints.down(768) {
                margin-left: 0px;
            }
        }

        &__heading {
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            color: rgba(0, 0, 0, 0.87);

            @include breakpoints.down(768) {
                font-size: 16px;
            }
        }
    }

    &__laser-heightmap {
        background: #fff;

        @include breakpoints.down(768) {
            padding-top: 60px;
        }

        &__heading {
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            color: rgba(0, 0, 0, 0.87);

            @include breakpoints.down(768) {
                font-size: 16px;
            }
        }
    }

    &__breakdown {
        padding: 0;
        background: #fff;

        .container {
            display: flex;

            @include breakpoints.down(768) {
                display: block;
            }
        }

        &--overall,
        &--front,
        &--back {
            padding-top: 40px;
            padding-bottom: 60px;

            @include breakpoints.down(1024) {
                padding-bottom: 40px;
            }
        }

        &__front {
            background: #ffffff;
            border: 1px solid #e0e0e0;
            border-radius: 8px;
            width: 100%;
            padding: 20px;
        }

        &__back {
            background: #ffffff;
            border: 1px solid #e0e0e0;
            border-radius: 8px;
            width: 100%;
            padding: 20px;
            margin-left: 12px;

            @include breakpoints.down(768) {
                margin-left: 0px;
                margin-top: 30px;
            }
        }

        &__table {
            width: 100%;
            margin-top: 30px;
        }

        &__table-cell {
            padding: 12px 0px;
        }

        &--overall {
            padding-top: 40px;
            padding-bottom: 0;

            @include breakpoints.down(768) {
                padding-top: 24px;
            }
        }

        &__container {
            display: flex;

            @include breakpoints.down(768) {
                flex-direction: column;
            }
        }

        &__score,
        &__scores {
            width: 100%;
            align-items: center;
            justify-content: center;

            &--card-price {
                margin-top: 10px;
                align-items: center;
                justify-content: left;
            }

            &--contained {
                background: #6c31bc;
                border-radius: 8px;

                &,
                .feed-view__breakdown__label,
                .feed-view__breakdown__value {
                    color: #fff;
                }

                .feed-view__breakdown__label,
                .feed-view__breakdown__value {
                    text-align: center;
                }
            }

            &-holder {
                display: inline-flex;
                align-items: stretch;

                @include breakpoints.down(768) {
                    padding: 0 !important;
                    max-width: 100% !important;
                }
            }
        }

        &__score {
            &-holder {
                width: 100%;
                max-width: 224px;

                @include breakpoints.down(768) {
                    padding-bottom: 24px !important;
                }
            }
        }

        &__scores {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            &-holder {
                width: 100%;
                margin-top: 30px;
            }

            &-card-price {
                margin-top: 10px;
            }

            &-score {
                width: 25%;
                padding: 24px 0;
                display: inline-flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                @include breakpoints.down(768) {
                    width: 50%;
                }

                &--full {
                    width: 100%;
                }
            }
        }

        &__label,
        &__value {
            width: 100%;
            letter-spacing: 0.2px;
        }

        &__label {
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 8px;

            &--magnify {
                font-size: 16px;
                line-height: 24px;
            }
        }

        &__value {
            font-weight: 700;
            font-size: 20px;
            line-height: 30px;

            &-label,
            &--magnify {
                line-height: 48px;
                text-transform: uppercase;
            }

            &-label {
                font-size: 26px;
            }

            &--magnify {
                font-size: 48px;
            }
        }
    }

    &__images {
        padding-bottom: 40px;
        background: #fff;

        .container {
            height: 530px;

            @media only screen and (min-width: 768px) {
                height: 410px;
            }

            @media only screen and (min-width: 1280px) {
                height: 530px;
            }
        }

        @include breakpoints.down(768) {
            padding-bottom: 0px;
        }

        &__image {
            padding: 10px;
            border-radius: 20px;

            @include breakpoints.down(768) {
                padding: 5px 5px 0px 0px;
                border-radius: 8px;
                width: 260px;
                height: 183px;
            }

            @media only screen and (min-width: 360px) {
                height: 163px;
            }

            @media only screen and (min-width: 375px) {
                height: 170px;
            }

            @media only screen and (min-width: 390px) {
                height: 177px;
            }

            @media only screen and (min-width: 414px) {
                height: 189px;
            }

            @media only screen and (min-width: 412px) {
                height: 183px;
            }

            @media only screen and (min-width: 540px) {
                width: 100%;
                height: 100%;
            }
        }

        &__caption {
            position: absolute;
            margin-top: -180px;
            transform: rotate(90deg);
            color: white;
            background: rgba(31, 31, 31, 0.49);
            border-radius: 20px;
            padding: 10px 20px;

            @include breakpoints.down(1024) {
                margin-top: -120px;
            }

            @include breakpoints.down(768) {
                margin-top: -100px !important;
                margin-left: -20px;
            }

            @media only screen and (min-width: 1024px) {
                margin-top: -136px;
            }

            @media only screen and (min-width: 658px) {
                margin-top: -110px;
                margin-left: 0px;
            }

            @media only screen and (min-width: 1280px) {
                margin-top: -180px;
            }

            @media only screen and (min-width: 540px) and (max-width: 720px) {
                margin-top: -80px;
                margin-left: -20px;
            }
        }

        &__card {
            @include breakpoints.down(768) {
                width: 235px;
            }
        }

        &__cards-front {
            display: flex;
            flex-wrap: wrap;
            width: 433px;
            height: 100%;
            transform: translateX(-100%) rotate(-90deg);
            transform-origin: right top;

            @include breakpoints.down(768) {
                width: 470px;
                height: 66%;
            }

            @media only screen and (min-width: 360px) {
                width: 470px;
                height: 60%;
            }

            @media only screen and (min-width: 412px) {
                width: 470px;
                height: 70%;
            }

            @media only screen and (min-width: 600px) and (max-width: 1024px) {
                width: 322px;
            }

            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                width: 254px;
            }

            @media only screen and (min-width: 540px) and (max-width: 720px) {
                width: 175px;
            }

            @media only screen and (min-width: 820px) and (max-width: 1180px) {
                width: 270px;
            }

            @media only screen and (min-width: 912px) and (max-width: 1368px) {
                width: 305px;
            }

            @media only screen and (min-width: 1024px) {
                width: 325px;
            }

            @media only screen and (min-width: 1280px) {
                width: 433px;
            }
        }

        &__cards-back {
            display: none;
            width: 433px;
            height: 100%;
            flex-wrap: wrap;
            transform: translateX(-100%) rotate(-90deg);
            transform-origin: right top;

            @include breakpoints.down(768) {
                width: 470px;
                height: 66%;
            }

            @media only screen and (min-width: 600px) and (max-width: 1024px) {
                width: 322px;
            }

            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                width: 254px;
            }

            @media only screen and (min-width: 540px) and (max-width: 720px) {
                width: 175px;
            }

            @media only screen and (min-width: 820px) and (max-width: 1180px) {
                width: 270px;
            }

            @media only screen and (min-width: 912px) and (max-width: 1368px) {
                width: 305px;
            }

            @media only screen and (min-width: 1024px) {
                width: 325px;
            }

            @media only screen and (min-width: 1280px) {
                width: 433px;
            }
        }
    }

    &__slider {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .slick-arrow {
            width: 32px;
            height: 32px;
            z-index: 3;
            background-color: rgba(0, 0, 0, 0.7);

            &:before {
                color: #fff;
            }

            &.slick-next {
                right: 0;

                &:before {
                    @include mixins.materialIcon('chevron_right');
                }
            }

            &.slick-prev {
                left: 0;

                &:before {
                    @include mixins.materialIcon('chevron_left');
                }
            }
        }
    }
}

.SubmissionButton {
    padding: 14px 24px;
    background-color: #20bfb8;
    border-radius: 28px;
    display: inline-block;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.14), 0 2px 1px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.2);
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.35px;
    color: #fff;
    text-transform: uppercase;
    transition: background-color 0.2s ease-in-out;
    text-align: center;

    &:hover {
        background-color: lighten(#20bfb8, 10%);
    }
}

.feed-pagination-section {
    display: flex;
    justify-content: flex-end;
    margin: 20px 20px;

    &__pagination {
        margin-top: -22px;
        width: 100px;
    }
}

.ib-image {
    transform: translate(-50%, -50%) rotate(-90deg) !important;
}
