.Home-ukReady {
    background-color: #fff;
    padding-top: 160px;
    padding-bottom: 130px;
    .Home-sectionContentText {
        margin-bottom: 0;
    }
    .Home-sectionContentHeadline {
        font-size: 24px;
    }
    @media screen and (max-width: 599px) {
        padding-top: 20px;
        padding-bottom: 60px;
    }
}

.Home-ukReadyTitle {
    font-weight: 500;
    font-size: 50px;
    line-height: 50px;
    letter-spacing: 0;
    @media screen and (max-width: 599px) {
        font-size: 26px;
        line-height: 26px;
    }
}

.Home-ukReadyLink {
    font-weight: 500;
    font-size: 60px;
    line-height: 80px;
    letter-spacing: 0;
    color: #30016e;
    margin-left: 39px;
    margin-bottom: 35px;

    @media screen and (max-width: 599px) {
        font-size: 20px;
        line-height: 26px;
        margin: 0;
    }
}
