.Home-searchCards {
    background-color: #5b35ab;
    padding-bottom: 145px;
    position: relative;
    z-index: 1;

    @media screen and (max-width: 1280px) {
        padding-bottom: 50px;
    }
    @media screen and (max-width: 699px) {
        padding-top: 50px;
    }

    .Home-sectionContentHeadline {
        font-weight: 900;
        font-size: 40px;
        line-height: 40px;
        max-width: 100%;
        @media screen and (max-width: 699px) {
            font-size: 24px;
            font-weight: 900;
            line-height: 38px;
            letter-spacing: 0px;
            text-align: left;
        }
    }

    .Home-sectionContentCaption,
    .Home-sectionContentHeadline {
        color: white;
        width: 550px;
    }

    @media screen and (max-width: 699px) {
        .Home-sectionContentCaption {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 1.5px;
            text-align: left;
        }

        .Home-sectionColumn--half--right {
            padding: 0 !important;
        }
    }

    .Home-sectionColumn--half--left {
        margin-top: 30px;
    }
}

.Home-searchCardsBackground {
    position: absolute;
    height: 100%;
    width: 75%;
    top: 75px;
    right: 0;
    bottom: 0;
    z-index: -1;

    @media screen and (max-width: 699px) {
        width: 100%;
        height: 314px;
        top: 50%;
    }
}

.Home-searchCards-search-form {
    width: 89%;
    @media screen and (max-width: 699px) {
        width: 100%;
    }
}
