.Home-ukMobileApps {
    @media screen and (max-width: 599px) {
        padding-top: 56px;
        padding-bottom: 95px;
    }
}

.Home-ukMobileApps-title {
    font-weight: 800;
    font-size: 36px;
    line-height: 36px;
    letter-spacing: 0;
    text-align: center;

    @media screen and (max-width: 599px) {
        font-size: 21px;
        line-height: 21px;
    }
}

.Home-ukMobileApps-linksRow {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    @media screen and (max-width: 599px) {
        margin-top: 95px;
    }
    .Home-ukMobileApps-storeButtonLink {
        width: 35%;
        margin: 0 21.5px;
        @media screen and (max-width: 599px) {
            margin: 0 7.5px;
        }

        .Home-ukMobileApps-storeButton {
            height: auto;
            border-radius: 10px;
        }
    }
}

.Home-ukMobileAppsImageContainer {
    @media screen and (max-width: 599px) {
        width: 100%;
        margin: 38px 58px 0 58px;
    }
}

.Home-ukMobileAppsImage {
    margin-top: -40px;
    @media screen and (max-width: 599px) {
        margin-top: 0;
    }
}
