@use './breakpoints';
@tailwind base;
@tailwind components;

@layer components {
    .container {
        @include breakpoints.down(sm) {
            max-width: 100%;
        }
    }
}

// Same as the MuiBaseline to remove flashbacks
html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
*,
*::before,
*::after {
    box-sizing: inherit;
}
strong,
b {
    font-weight: 700;
}
body {
    color: rgba(0, 0, 0, 0.87);
    margin: 0;
    font-size: 0.875rem;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    background-color: #fafafa;
}
body::backdrop {
    background-color: #fafafa;
}
