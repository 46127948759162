@use '../../../shared/breakpoints';

.pop-hero {
    $color: #fff;
    background-color: #160056;

    &__container {
        display: flex;
        @apply pb-1;
    }

    &__text {
        @apply grow;
        @apply flex justify-center items-start flex-col;
        padding: 30px 0;
        z-index: 1;

        &-heading,
        &-subheading,
        &-series-heading,
        &-series-subheading {
            color: $color;
        }

        &-heading,
        &-series-heading {
            color: $color;
            font-weight: 700;
            font-size: 48px;
            line-height: 56px;
            letter-spacing: 0.2px;
            margin-bottom: 4px;
            @include breakpoints.down(sm) {
                font-size: 24px;
                line-height: 30px;
                @apply mb-0;
            }
        }

        &-subheading {
            margin-top: 5px;
            font-size: 14px;
            line-height: 20px;
            padding-bottom: 28px;
            @include breakpoints.down(sm) {
                font-size: 14px;
            }
        }

        &-series-heading {
            font-size: 28px;
            line-height: 38px;
            @include breakpoints.down(sm) {
                font-size: 20px;
                line-height: 30px;
                @apply mb-0;
            }
        }

        &-series-subheading {
            font-size: 14px;
        }

        &-stats {
            padding: 0;
            border: 1px solid rgba(255, 255, 255, 0.5);
            width: 550px;
            border-radius: 8px;
            display: flex;
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.10000000149011612px;
            text-align: center;
            @include breakpoints.down(sm) {
                width: 100%;
            }
        }

        &-stat-column {
            text-align: center;
            width: 33%;
            padding: 10px;
            display: inline-grid;

            &-value {
                font-weight: 500;
                font-size: 20px;
                @include breakpoints.down(sm) {
                    font-size: 16px !important;
                }
            }

            &-title {
                font-weight: 400;
                font-size: 14px;
                color: #ffffffb2;
            }
        }

        &-stat-column.with-right-border {
            height: 72px;
            border-right: 1px solid rgba(255, 255, 255, 0.5);
        }
    }

    &__splash {
        @apply w-4/12 flex justify-end items-center;

        @include breakpoints.down(xs) {
            @apply w-3/12;
        }

        &-image {
            @include breakpoints.down(xs) {
                max-width: 175px;
            }
        }
    }

    &__logo {
        @apply w-1/12 mr-1 flex justify-start items-center;

        @include breakpoints.down(xs) {
            @apply w-2/12;
        }

        &-image {
            &--holder {
                @apply bg-white w-20 h-20 rounded flex items-center;
                @include breakpoints.down(xs) {
                    @apply w-16 h-16;
                }
            }
        }
    }

    &__category-logo {
        &-image {
            @apply h-9 w-auto;
            margin: 11px 0 5px 0;
        }
    }

    &__breadcrumbs {
        @apply rounded text-sm font-sans w-full pt-7 pb-1;
        @include breakpoints.down(xs) {
            @apply text-xs;
        }
        &__list {
            @apply flex list-none;

            li {
                @apply text-white pr-1;
            }
            &__link {
                @apply font-bold;
                letter-spacing: 0.2px;
                color: #42e8e0;
            }
        }
    }
}
