@use '../../../shared/breakpoints';

.feed-hero {
    $color: rgb(255, 255, 255);
    background-image: linear-gradient(to bottom, rgba(3, 3, 66, 0.5), rgba(0, 0, 190, 0.5)),
        url('/assets/images/feedpage-background.png');
    background-repeat: round;

    &__container {
        width: 100%;
        padding: 10px;
    }

    &__search-box {
        margin: auto;
        width: 50%;
        @include breakpoints.down(sm) {
            width: 100%;
        }
    }

    &__input {
        width: 630px;
        height: 48px;
        left: 400px;
        top: 234px;
        background: #ffffff;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
        border-radius: 24px;
        padding: 20px 37px;
        @include breakpoints.down(sm) {
            width: 100%;
        }
    }

    &__clear-button {
        position: absolute;
        margin-left: -30px;
        margin-top: 12px;
        color: rgba(0, 0, 0, 0.54);

        @include breakpoints.down(sm) {
            position: absolute;
        }
    }

    &__search-icon {
        position: absolute;
        margin-left: 10px;
        margin-top: 12px;
        width: 10px;
        color: rgba(0, 0, 0, 0.54);

        @include breakpoints.down(sm) {
            position: absolute;
        }
    }

    &__text {
        padding: 24px 0;
        @include breakpoints.down(xs) {
            @apply items-center w-full;
            padding-top: 12px;
        }

        &-heading,
        &-subheading {
            color: $color;
        }

        &-heading {
            text-align: center;
            color: $color;
            font-weight: 700;
            font-size: 48px;
            line-height: 56px;
            letter-spacing: 0.2px;
            margin-bottom: 4px;
            @include breakpoints.down(sm) {
                font-size: 32px;
            }
        }

        &-subheading {
            text-align: center;
            font-size: 16px;
            @include breakpoints.down(sm) {
                font-size: 14px;
            }
        }
    }

    &__splash {
        @apply w-4/12 flex justify-end items-center;

        @include breakpoints.down(xs) {
            display: none;
        }
    }

    &__splash-image {
    }
}
