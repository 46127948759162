.Pricing-uk {
    background-color: #fff;
    letter-spacing: 0;
    text-align: center;
    padding-top: 92px;
    padding-bottom: 140px;

    .Pricing-uk-title {
        font-weight: 500;
        font-size: 48px;
        line-height: 48px;

        @media screen and (max-width: 599px) {
            font-size: 26px;
            line-height: 26px;
        }
    }
}
