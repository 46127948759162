.Home-ukTestimonials {
    letter-spacing: 0;
    text-align: center;
    padding: 100px 0;
    @media screen and (max-width: 768px) {
        padding: 50px 0;
    }

    .Home-ukTestimonials-slick {
        width: 100%;
        display: block;
        @media screen and (max-width: 768px) {
            margin-top: 54px;
        }
    }

    .Home-ukTestimonial {
        width: 100%;
        display: flex;

        &.slick-slide {
            @media screen and (max-width: 768px) {
                flex-direction: column !important;
            }
        }
    }

    .Home-ukTestimonialImageContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0;
        width: 33.33333%;
        @media screen and (max-width: 768px) {
            width: 100%;
            align-items: center;
            justify-content: center;
        }
    }
    .Home-ukTestimonialContentContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 0 40px;
        width: 66.66666%;
        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }
    .Home-ukTestimonialsImage {
        height: 406px;
        width: auto;
        border-radius: 20px;
        @media screen and (max-width: 768px) {
            height: 223px;
        }
    }

    .Home-ukTestimonial.slick-slide {
        display: flex;
        flex-direction: row;
    }

    .Home-ukTestimonials-name,
    .Home-ukTestimonials-title,
    .Home-ukTestimonials-content {
        font-size: 26px;
        line-height: 26px;

        @media screen and (max-width: 768px) {
            font-size: 15px;
            line-height: 15px;
        }
    }
    &-name {
        font-weight: 500;
        @media screen and (max-width: 768px) {
            margin-top: 8px;
        }
    }
    &-title {
        font-weight: 300;
    }
    &-content {
        font-weight: 300;
        margin-top: 58px;
        margin-bottom: 47px;
        max-width: 600px;
    }

    .arrows {
        .material-icons {
            font-size: 65px;
            margin: 0 64.5px;
            font-variation-settings: 'FILL' 0, 'wght' 200, 'GRAD' 0, 'opsz' 58;
        }
    }
}
