.Home-about {
    @media screen and (max-width: 767px) {
        padding: 48px 0;

        .Home-sectionLayer--topLeft {
            display: none;
        }

        .Home-sectionImageHolder {
            margin-top: 48px;
            margin-bottom: 0;
        }

        .Home-sectionContainer {
            flex-direction: column-reverse;
        }
    }
}
