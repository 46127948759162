.page__body.page--terms-and-conditions {
    background-color: #fff;
}

.terms-conditions {
    &__hero {
        &-holder {
            @apply max-w-7xl mx-auto py-16 sm:py-24 lg:px-8;
        }
        &-inner {
            text-align: center;
        }
        &-heading {
            @apply mt-1 font-extrabold text-gray-900 text-2xl sm:text-5xl sm:tracking-tight lg:text-6xl uppercase;
        }
        &-caption {
            @apply text-xs font-semibold text-indigo-600 tracking-wide uppercase mt-3;
        }
        &-subheading {
            @apply max-w-xl mt-5 mx-auto text-xl text-gray-500;
        }
    }

    &__content {
        @apply flex flex-col items-center mt-8;
        &-heading {
            @apply text-xl sm:text-2xl font-semibold tracking-wide uppercase mb-2;
        }
        &-section {
            @apply mb-12;
            max-width: 768px;
        }
        &-text {
            @apply text-xs sm:text-sm text-gray-600 leading-6 sm:leading-7;
        }
        &-link {
            @apply font-semibold text-indigo-900;
        }
    }
}
